<template>
  <div class="wlf-quiz__entry">
    <div class="wlf-quiz__introduction">
      <div class="text" v-html="entryPoint.text"></div>
    </div>
    <div class="wlf-quiz__entry-point wlf-quiz__entry-point--default">
      <div class="wlf-answers">
        <template v-for="(path, index) in entryPoint.product_options"
                  v-bind:key="index">
          <button
              @click="setProductPath(path)"
              class="wlf-answer text-h4"
              :class="{
							'is-active' : ( productPath === path.internal_value ),
						}"
              v-html="path.answer_text"></button>
          <div class="wlf-quiz__action mobile"
               v-if="productPath && productPath.includes('not_sure') && productPath === path.internal_value">
            <button
                @click="submitEntry"
                class="wlf-submit-entry s-button"
                :disabled="buttonDisabled"
                :class="{ 'is-active': !buttonDisabled, 'disabled': buttonDisabled }"
            >
              Next
            </button>
          </div>
          <template v-if="productPath && !productPath.includes('not_sure') && productPath === path.internal_value">
            <div class="wlf-secondary-answers">
              <template v-for="(product, pIndex) in store.getStarted.productPathOptions"
                        v-bind:key="pIndex">
                <div class="product-button-wrap">
                  <button
                      @click="setProduct(product)"
                      class="wlf-answer text-h4"
                      :class="{
							'is-active' : ( store.form.get_started_product_selection === product.get_started_product_value ),
						}">{{ product.post_title }}
                  </button>
                  <div class="product-dialog-button-wrapper" v-if="product.tooltip_description">
                    <button class="product-dialog-button" @click="toggleDialog(product.post_title)">
                      <QuestionMarkCircleIcon class="h-icon"/>
                    </button>
                  </div>
                </div>
              </template>
            </div>
          </template>
        </template>
      </div>
      <template v-if="productPath && !productPath.includes('not_sure')">
        <div class="wlf-secondary-answers desktop">
          <template v-for="(product, index) in store.getStarted.productPathOptions"
                    v-bind:key="index">
            <div class="product-button-wrap">
              <button
                  @click="setProduct(product)"
                  class="wlf-answer text-h4"
                  :class="{
							'is-active' : ( store.form.get_started_product_selection === product.get_started_product_value ),
						}">{{ product.post_title }}
              </button>
              <div class="product-dialog-button-wrapper" v-if="product.tooltip_description">
                <button class="product-dialog-button" @click="toggleDialog(product.post_title)">
                  <QuestionMarkCircleIcon class="h-icon"/>
                </button>
              </div>
            </div>
          </template>
        </div>
      </template>
    </div>
    <div class="wlf-quiz__action" :class="{
      'not-sure': (productPath && productPath.includes('not_sure'))
    }">
      <button
          @click="submitEntry"
          class="wlf-submit-entry s-button"
          :disabled="buttonDisabled"
          :class="{ 'is-active': !buttonDisabled, 'disabled': buttonDisabled }"
      >
        Next
      </button>
    </div>
    <Dialog v-for="(dialog, index) in productDialogs"
            :key="index"
            :open="dialog.open"
            class="dialog"
            role="dialog"
            :id="'dialog-' + index"
            :aria-labelledby="'dialog-title-' + index"
            :aria-describedby="dialog.description ? 'dialog-desc-' + index : null"
            @keydown.esc="dialog.open = false">
      <div class="dialog-content" tabindex="-1">
        <div class="dialog-title-wrap">
          <div class="dialog-title" :id="'dialog-title-' + index">
            <component v-if="dialog.icon" :is="dialog.icon" class="h-icon"/>
            <span v-html="dialog.title"></span>
          </div>
          <button @click="dialog.open = false" aria-label="Close dialog">
            <XMarkIcon class="h-icon"/>
          </button>
        </div>
        <p v-if="dialog.description" :id="'dialog-desc-' + index" v-html="dialog.description"></p>
        <button class="s-button--plain dialog-close" @click="dialog.open = false">Close Window</button>
      </div>
    </Dialog>
    <Disclaimers section="get_started"/>
  </div>
</template>

<script setup>
import {ref, computed, watch, onMounted} from 'vue'
import {useEntryDataStore} from './stores/entryData'
import Disclaimers from "./Disclaimers.vue";
import {QuestionMarkCircleIcon, XMarkIcon} from "@heroicons/vue/24/outline";
import Dialog from "./components/Dialog.vue";

const emit = defineEmits(['complete', 'change-path'])

const quizData = GET_STARTED.quizData
const entryPoint = quizData.entry_point['default']

const store = useEntryDataStore()

const analytics = {
  category: 'webtool_getstarted'
}

const initialEventSent = ref(false)

const buttonDisabled = ref(true)

const productPath = computed(() => {
  return store.form.get_started_path_selection
})

const productSelected = computed(() => {
  return store.form.get_started_product_selection
})

const productDialogs = ref({})

const submitEntry = () => {
  window.dataLayer.push({
    event: 'accordion_completed',
    event_name: 'accordion_completed',
    event_category: analytics.category,
    event_label: '',
  })

  if (store.form.get_started_path_selection.includes('whole_life')) {
    //   store.quoteFields.product = productSelected.value
    if (productSelected.value.includes('100')) {
      store.form.get_started_path_selection = 'term_100_life_product_in_mind'
      // store.form.hubspot_form = 'whole_life'
    }
    // store.form.hubspot_form = productSelected.value.includes('20Pay')
    //     ? '20_pay_whole_life'
    //     : 'whole_life'
    store.form.quote_product_selection = productSelected.value
  }

  store.getStarted.entryCompleted = true
  emit('complete')
}

const setProductPath = (path) => {
  if (!store.getStarted.initialEvent) {
    window.dataLayer.push({
      event: 'accordion_started',
      event_name: 'accordion_started',
      event_category: analytics.category,
      event_label: '',
    })
    store.getStarted.initialEvent = true
  }
  window.dataLayer.push({
    event: 'accordion_engagement',
    event_name: 'accordion_engagement',
    event_category: analytics.category,
    event_label: path.internal_value
  })

  store.form.get_started_path_selection = path.internal_value
  store.getStarted.productPathOptions = path.answers
  initializeProductDialogs(path.answers)
  store.getStarted.fullPath = path
  emit('change-path', store.getStarted.fullPath.internal_value)
}

const setProduct = (product) => {
  window.dataLayer.push({
    event: 'accordion_engagement',
    event_name: 'accordion_engagement',
    event_category: analytics.category,
    event_label: product.get_started_product_value
  })

  store.form.get_started_product_selection = product.get_started_product_value
  store.form.quote_product_selection = product.quote_product_value
  store.form.product_name = product.post_title
  // set quote as well because it needs to track the questions answered, specifically for the quote
  store.quoteFields.quote_product_selection = product.quote_product_value
  if (product.post_title.includes('100')) {
    emit('change-path', 'term_life_product_in_mind')
  } else {
    emit('change-path', store.getStarted.fullPath.internal_value)
  }
}

function initializeProductDialogs(products) {
  if (products) {
    products.forEach((product) => {
      productDialogs.value[product.post_title] = {
        open: false,
        icon: QuestionMarkCircleIcon,
        title: product.post_title,
        description: product.tooltip_description
      }
    })
  }
}

function toggleDialog(title) {
  if (productDialogs.value[title]) {
    productDialogs.value[title].open = !productDialogs.value[title].open
  }
}

watch([productPath, productSelected], (
    [newProductPath, newProductSelected], [oldProductPath, oldProductSelected]
) => {
  if (!newProductPath) {
    buttonDisabled.value = true
    return
  }
  if (oldProductPath !== newProductPath) {
    store.form.get_started_product_selection = null
    store.getStarted.entryCompleted = false
  }
  if (newProductPath.includes('not_sure')) {
    buttonDisabled.value = false
    return
  }
  if (newProductPath && newProductSelected) {
    buttonDisabled.value = false
    return
  }
  buttonDisabled.value = true
})

onMounted(() => {
  window.dataLayer.push({
    event: 'get started',
    event_name: 'get started',
    event_category: productPath.value,
    event_label: document.location.pathname,
  })
  if (productPath.value && productPath.value.includes('not_sure')) {
    buttonDisabled.value = false
  }
  if (productPath.value && productSelected.value) {
    buttonDisabled.value = false
  }
  if (store.getStarted.productPathOptions) {
    initializeProductDialogs(store.getStarted.productPathOptions)
  }
})
</script>

<style scoped>
.wlf-quiz__introduction {
  margin: 35px auto;
}

.wlf-quiz__introduction h3 {
  margin-bottom: 25px;
}

.wlf-quiz__action {
  text-align: center;
  margin: 35px auto;
}

.wlf-answers {
  margin: 30px auto;
  display: flex;
  justify-content: center;

  > .wlf-secondary-answers {
    display: block;

    @media (min-width: 900px) {
      display: none
    }
  }
}

.wlf-secondary-answers {
  &.desktop {
    display: none;

    @media (min-width: 900px) {
      display: flex;
      justify-content: center;
      gap: 2rem;
    }
  }
}

.wlf-quiz__action {
  &.mobile {
    display: block;

    @media (min-width: 900px) {
      display: none;
    }
  }

  &.not-sure {
    display: none;

    @media (min-width: 900px) {
      display: block;
    }
  }
}

.wlf-answer {
  display: block;
  margin: 1rem;
  padding: 2rem;
  background: white;
  flex: 1 1 0;
  border-radius: 0.75rem;
  cursor: pointer;
  transition: all .4s ease;
  width: 100%;
  border: 1px solid rgba(30, 41, 59, 0.10);

  &:hover,
  &:focus {
    border: 1px solid transparent;
    outline-offset: 2px;
    outline: var(--blue) solid 2px;
  }

  &.is-active {
    --color__heading-text: white;
    --color__body-text: white;
    color: white;
    background: var(--blue);
  }

  p:last-of-type {
    margin: 0;
  }
}

.wlf-answer * {
  transition: all .4s ease;
  font-weight: 300;
}

.wlf-product_confirmation-answers {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.wlf-product_confirmation-answer {
  background: white;
  padding: 25px 35px;
  border-radius: 0.75rem;
  border: none;
  cursor: pointer;
  margin: 15px;
  transition: all 0.4s ease;
}

.wlf-product_confirmation-answer.is-active,
.wlf-product_confirmation-answer:hover,
.wlf-product_confirmation-answer:focus {
  color: white;
  background: var(--blue);
}

@media screen and (max-width: 1100px) {

  .wlf-answer {
    padding: 20px;
  }

}

@media screen and (max-width: 900px) {
  .wlf-answers {
    display: block;
  }

  .wlf-answer {
    margin: 20px 0;
  }

}

.product-button-wrap {
  display: flex;
  align-items: center;
  gap: 1rem;

  @media (min-width: 900px) {
    gap: 0.25rem;
  }

  .product-dialog-button-wrapper {
    width: 24px;
  }

  .product-dialog-button {
    width: auto;
    padding: 0;
    border: none;
    background: none;
    color: black;
    text-align: initial;
    flex-grow: 0;
    cursor: pointer;

    &:hover,
    &:focus {
      border: none;
      outline: none;
    }
  }
}

.dialog {
  border: none;
  border-radius: 0.75rem;
  width: 75vw;

  .dialog-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .dialog-title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dialog-title {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      font-size: 1.25rem;
    }

    button {
      border: none;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2rem;
      width: 2rem;
      cursor: pointer;
    }
  }

  .dialog-close {
    justify-content: center;
  }
}
</style>
