<template>
  <div>
    <div class="wlf-get-started__path">
      <div class="questions">
        <div v-for="(questionData, index) in displayedQuestions" :key="index">
          <get-started-question
              :question="questionData"
              :index="index"
              @answer="handleAnswer"
              @complete="emits('complete')"
          />
        </div>
      </div>
    </div>
    <Disclaimers section="needs_assessment"/>
    <slot name="navigation"></slot>
  </div>
</template>

<script setup>
import {ref, computed, onMounted} from 'vue'
import GetStartedQuestion from './GetStartedQuestion.vue'
import {useEntryDataStore} from './stores/entryData'
import Disclaimers from "./Disclaimers.vue";

const emits = defineEmits(['complete'])

const store = useEntryDataStore()

const quizData = GET_STARTED.quizData

const analytics = {
  category: 'webtool_needs'
}

const quizPath = computed(() => {
  const yourNeedsQuestions = Array.isArray(quizData.your_needs.goals)
      ? quizData.your_needs.goals
      : [quizData.your_needs.goals]

  // const keyQuestions = quizData.key_questions || []
  // ...keyQuestions
  return {
    title: 'Quiz Questions',
    slug: 'quiz-questions',
    questions: [...yourNeedsQuestions,],
  }
})

const questionIndex = ref(0)
const displayedQuestions = ref([])

const buttonDisabled = ref(true)

const initializeQuiz = () => {
  // If the page was refreshed and questions were added, make them the questions we display,
  // otherwise display the quiz initially from the start
  if (store.needsAssessment.questions.length) {
    displayedQuestions.value = store.needsAssessment.questions
    if (!(questionIndex.value < quizPath.value.questions.length - 1)) {
      buttonDisabled.value = false
    }
  } else {
    displayedQuestions.value.push(quizPath.value.questions[0])
    store.needsAssessment.questions = displayedQuestions.value
  }
}

const handleAnswer = (answerData) => {
  const answer = answerData.answer
  const index = answerData.index

  // Remove subsequent questions if answer changes
  displayedQuestions.value = displayedQuestions.value.slice(0, index + 1)
  store.needsAssessment.questions = displayedQuestions.value
  questionIndex.value = index - 1

  if (!answer) {
    buttonDisabled.value = true
    // If the answer is null/toggled off/submitted null on purpose, we don't want to show any new questions
    return
  }

  const question = displayedQuestions.value[index]

  if (!store.getStarted.quizInitialEvent) {
    window.dataLayer.push({
      event: 'accordion_started',
      event_name: 'accordion_started',
      event_category: analytics.category,
      event_label: '',
    })
    store.getStarted.quizInitialEvent = true
  }

  window.dataLayer.push({
    event: 'accordion_engagement',
    event_name: 'accordion_engagement',
    event_category: analytics.category,
    event_label: question.title
  })

  if (answer.related_product) {
    if (store.needsAssessment.productRecommendations.length > 0) {
      store.needsAssessment.productRecommendations = []
    }
    // There should only be one product here, limit is 1 in ACF
    answer.related_product.forEach(related_product => {
      // if (related_product.product_title !== '' || related_product.product) {
      store.needsAssessment.productRecommendations.push(related_product)
      if (related_product.product[0]) {
        store.form.product_name = related_product.product[0].post_title
        store.form.quote_product_selection = related_product.product[0].quote_product_value
        store.quoteFields.quote_product_selection = related_product.product[0].quote_product_value
      }
      // }
    })
  }

  if (answer.follow_up) {
    displayedQuestions.value.push(answer.follow_up)
    store.needsAssessment.questions = displayedQuestions.value
  } else {
    if (questionIndex.value < quizPath.value.questions.length - 1) {
      questionIndex.value++
      if (index > questionIndex.value) {
        // If the previous question was the one inserted, then we need to fix the index
        questionIndex.value = index
      }
      displayedQuestions.value.push(quizPath.value.questions[questionIndex.value])
      store.needsAssessment.questions = displayedQuestions.value
    } else {
      buttonDisabled.value = false
      emits('complete')
    }
  }
}

initializeQuiz()

onMounted(() => {
  window.dataLayer.push({
    event: 'accordion_opened',
    event_name: 'accordion_opened',
    event_category: analytics.category,
    event_label: '',
  })
})
</script>

<style lang="scss" scoped>
.question {
  flex: 1 1 auto;
  text-align: center;
  padding: 25px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.wlf-get-started__entry {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.wlf-get-started__review {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex: 1 1 0;
}

.whole-life-options {
  display: flex;
  gap: 0.5rem;
}

.wlf-quiz__action {
  text-align: center;
  margin: 35px auto;
}
</style>
