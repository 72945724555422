import { defineStore } from 'pinia'

export const useStepStore = defineStore('stepData', {
    state: () => {
        return {
            currentStepKey: 'get-started',
            completedSteps: [],
        }
    },
    persist: {
        enabled: true,
        strategies: [
            {
                key: 'stepData',
                storage: localStorage,
            },
        ],
    },
})
