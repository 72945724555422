// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.question[data-v-24a92ef7] {
  flex: 1 1 auto;
  text-align: center;
  padding: 25px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.wlf-get-started__entry[data-v-24a92ef7] {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.wlf-get-started__review[data-v-24a92ef7] {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex: 1 1 0;
}
.whole-life-options[data-v-24a92ef7] {
  display: flex;
  gap: 0.5rem;
}
.wlf-quiz__action[data-v-24a92ef7] {
  text-align: center;
  margin: 35px auto;
}
`, "",{"version":3,"sources":["webpack://./../assets/vue/GetStartedQuiz.vue"],"names":[],"mappings":"AACA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,sBAAsB;AAAA;AAGxB;EACE,cAAc;EACd,aAAa;EACb,uBAAuB;EACvB,sBAAsB;AAAA;AAGxB;EACE,aAAa;EACb,uBAAuB;EACvB,sBAAsB;EACtB,mBAAmB;EACnB,WAAW;AAAA;AAGb;EACE,aAAa;EACb,WAAW;AAAA;AAGb;EACE,kBAAkB;EAClB,iBAAiB;AAAA","sourcesContent":["\n.question {\n  flex: 1 1 auto;\n  text-align: center;\n  padding: 25px 0;\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n}\n\n.wlf-get-started__entry {\n  flex: 1 1 auto;\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n}\n\n.wlf-get-started__review {\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  align-items: center;\n  flex: 1 1 0;\n}\n\n.whole-life-options {\n  display: flex;\n  gap: 0.5rem;\n}\n\n.wlf-quiz__action {\n  text-align: center;\n  margin: 35px auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
