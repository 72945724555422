import {defineStore} from 'pinia'

export const useEntryDataStore = defineStore('entryData', {
    state: () => ({
        form: {
            get_started_path_selection: null,
            get_started_product_selection: null,
            goals: null,
            goal_question_key: null,
            how_old_are_you: null,
            employment_status: null,
            marital_status: null,
            recommended_coverage: null,
            total_cash_needs: 0,
            total_needed_income_shortage: 0,
            total_available_assets: 0,
            leadsource: null,
            dob: null,
            adb: null,
            adb_amount: 0,
            adb_cost: 0,
            ctb: null,
            ctb_amount: 0,
            ctb_cost: 0,
            quote_product_selection: null,
            gio: null,
            gio_amount: 0,
            gio_cost: 0,
            payment_mode: null,
            sex: null,
            smoking_class: null,
            tdw: null,
            tdw_cost: 0,
            term_length: null,
            annual_premium_cost_quote: 0,
            basic_plan_cost_quote: 0,
            child_term_benefit_cost: 0,
            coverage_amount_from_quote: 0,
            total_disability_waiver_cost: 0,
            guaranteed_insurability_option_cost: 0,
            accidental_death_benefit_cost: 0,
            monthly_premium_cost: 0,
            quarterly_premium_cost: 0,
            firstname: '',
            lastname: '',
            province: '',
            phone: '',
            email: '',
            caslconsent: false,
            utm_campaign: null,
            utm_source: null,
            utm_medium: null,
            utm_term: null,
            utm_content: null,
            fintel_click_id: null,
            productid: null,
            hubspot_form: null,
            age: null,
            calculated_age: 30,
            product_name: null,
            health_match: null
        },
        getStarted: {
            entryCompleted: false,
            entryPoint: '',
            productTypeSelected: null,
            productTypeSelectedOptions: null,
            initialEvent: false,
            quizInitialEvent: false,
            questionInitialEvent: false,
            productPathOptions: null,
            fullPath: null
        },
        needsAssessment: {
            showSkipToWholeLife: false,
            productRecommendations: [],
            questions: [],
            recommendationComplete: false
        },
        doTheMath: {
            cash_needs: {},
            income_needs: {},
            available_assets: {},
            cash_needs_total: 0,
            income_needs_total: 0,
            available_assets_total: 0,
            total: 0,
            complete: false,
            initialEvent: false,
        },
        quote: {
            complete: false
        },
        quoteFields: {
            leadsource: null,
            quote_product_selection: null,
            dob: null,
            sex: null,
            smoking_class: null,
            coverage_amount_from_quote: 0,
            payment_mode: null,
            health_match: null,
            tdw: null,
            adb: null,
            adb_amount: 0,
            gio: null,
            gio_amount: 0,
            ctb: null,
            ctb_amount: 0
        },
        summary: {
            complete: false,
            annualPremium: 0,
            coverageListItems: []
        },
        footer: {
            shown: false
        },
        hubspot: {
            submitted: false
        }
    }),
    persist: {
        enabled: true,
        strategies: [
            {
                key: 'entryData',
                storage: localStorage,
            },
        ],
    },
})
