<script setup>
import { ref } from 'vue'

import products from '../../../images/products.png'
import productsHover from '../../../images/products-hover.png'

const isHovered = ref(false)
</script>

<template>
  <img
      :src="isHovered ? productsHover : products"
      @mouseover="isHovered = true"
      @mouseleave="isHovered = false"
      alt="Products Icon"
      class="h-icon-image"
  />
</template>

<style scoped lang="scss">

</style>
