// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.question-title[data-v-0d2fc308] {
  font-size: 1.75rem;
  color: var(--color__heading-text);
  margin-bottom: 1rem;
}
.question-text[data-v-0d2fc308] {
  margin: 0 auto;
}
.question-footer[data-v-0d2fc308] {
  margin-top: 40px;
}
.v-quiz__actions[data-v-0d2fc308] {
  margin: 0 auto;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./../assets/vue/GetStartedQuestion.vue"],"names":[],"mappings":"AACA;EACE,kBAAkB;EAClB,iCAAiC;EACjC,mBAAmB;AAAA;AAGrB;EACE,cAAc;AAAA;AAGhB;EACE,gBAAgB;AAAA;AAGlB;EACE,cAAc;EACd,WAAW;AAAA","sourcesContent":["\n.question-title {\n  font-size: 1.75rem;\n  color: var(--color__heading-text);\n  margin-bottom: 1rem;\n}\n\n.question-text {\n  margin: 0 auto;\n}\n\n.question-footer {\n  margin-top: 40px;\n}\n\n.v-quiz__actions {\n  margin: 0 auto;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
