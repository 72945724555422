<script setup>
import {computed, onMounted} from 'vue'
import {useEntryDataStore} from './stores/entryData'
import Disclaimers from "./Disclaimers.vue";

const content = PRODUCT_RECOMMENDATIONS;

const store = useEntryDataStore()

const emits = defineEmits(['complete'])

const products = computed(() => {
  return store.needsAssessment.productRecommendations
})

const analytics = {
  category: 'webtool_needs'
}

onMounted(() => {
  store.needsAssessment.recommendationComplete = true
})

</script>

<template>
  <div>
    <div class="wlf-product-recommendations">
      <p v-if="content.description" v-html="content.description"></p>
      <p>We may have found the perfect product<span v-if="products.length > 1">s</span> for your age and stage of
        life...
      </p>
      <div v-for="(recommendation, index) in products" :key="index">
        <h3>{{ recommendation.product_title }}</h3>
        <p v-html="recommendation.product_description"></p>
      </div>
    </div>
    <Disclaimers section="product_recommendations"/>
    <slot name="navigation"/>
  </div>
</template>

<style scoped>

</style>