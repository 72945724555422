<script setup>
import {computed, onMounted, ref} from 'vue'
import DoTheMathQuestion from './DoTheMathQuestion.vue'
import {useEntryDataStore} from './stores/entryData'
import {useMoneyFormatter} from "./composables/useMoneyFormatter";
import Disclaimers from "./Disclaimers.vue";

const {formatMoney} = useMoneyFormatter()

const quizData = DO_THE_MATH.quizData // Quiz Questions/Answers Localized Data (lib/get-started.php)

const store = useEntryDataStore()

const emit = defineEmits(['complete'])

const analytics = {
  category: 'webtool_calculator'
}

const completeSent = ref(false)

const quizPath = computed(() => {
  return [
    quizData.cash_needs,
    quizData.income_needs,
    quizData.available_assets
  ]
})

const totalAmount = computed(() => {
  const total =
      store.form.total_cash_needs +
      store.form.total_needed_income_shortage -
      store.form.total_available_assets;

  return total >= 0 ? total : 0;
})

const showTotal = computed(() => {
  return store.form.total_cash_needs > 0 && store.form.total_needed_income_shortage > 0 && store.form.total_available_assets > 0
})

function update() {
  store.doTheMath.total = totalAmount.value
  store.quoteFields.coverage_amount_from_quote = totalAmount.value
  store.form.coverage_amount_from_quote = totalAmount.value
  store.form.recommended_coverage = totalAmount.value
  if (showTotal.value && !completeSent.value) {
    window.dataLayer.push({
      event: 'accordion_completed',
      event_name: 'accordion_completed',
      event_category: analytics.category,
      event_label: '',
    })
    completeSent.value = true
  }
}

onMounted(() => {
  window.dataLayer.push({
    event: 'accordion_opened',
    event_name: 'accordion_opened',
    event_category: analytics.category,
    event_label: '',
  })
})
</script>

<template>
  <div>
    <div class="wlf-do-the-math__path">
      <div class="math-container">
        <div class="wlf-quiz__entry-point wlf-quiz__entry-point--default">
          <div class="wlf-quiz__introduction">
            <div class="text" v-html="quizData.content.content_before_steps"></div>
          </div>
        </div>
        <do-the-math-question v-for="(step, index) in quizPath" :key="index" :step="step"
                              @update="update()"/>
        <div class="recommended-coverage">
          <div class="total-amount">
            <div class="amount">Recommended Coverage: <span>{{ formatMoney(totalAmount, 0) }}</span></div>
            <div v-if="quizData.recommended_coverage.content" v-html="quizData.recommended_coverage.content"></div>
          </div>
          <div v-if="showTotal" class="calculation">
            <div class="item-box cash">
              <label>Cash Needs</label>
              <div class="input-wrapper">
                <input type="text" :value="formatMoney(store.form.total_cash_needs, 0)" disabled>
              </div>
            </div>
            <div class="math-symbol plus">+</div>
            <div class="item-box income">
              <label>Income Needs</label>
              <div class="input-wrapper">
                <input type="text" :value="formatMoney(store.form.total_needed_income_shortage, 0)" disabled>
              </div>
            </div>
            <div class="math-symbol equals1">=</div>
            <div class="item-box total">
              <label>Total Cash & Income Needs</label>
              <div class="input-wrapper">
                <input type="text"
                       :value="formatMoney(store.form.total_cash_needs + store.form.total_needed_income_shortage, 0)"
                       disabled>
              </div>
            </div>
            <div class="math-symbol minus">-</div>
            <div class="item-box assets">
              <label>Less Available Assets</label>
              <div class="input-wrapper">
                <input type="text" :value="formatMoney(store.form.total_available_assets, 0)" disabled>
              </div>
            </div>
            <div class="math-symbol equals2">=</div>
            <div class="item-box insurance">
              <label>Total Insurance Needed</label>
              <div class="input-wrapper">
                <input type="text" :value="formatMoney(totalAmount, 0)" disabled>
              </div>
            </div>
          </div>
        </div>
        <div class="wlf-quiz__introduction">
          <div class="text" v-html="quizData.content.content_after_steps"></div>
        </div>
      </div>
    </div>
    <Disclaimers section="do_the_math"/>
    <slot name="navigation"></slot>
  </div>
</template>

<style lang="scss">
.math-container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.recommended-coverage {
  border-radius: 0.75rem;
  border: 2px solid var(--blue);
  padding: 1rem;

  .total-amount {
    background-color: #ffffff;
    border-radius: 0.75rem;
    width: initial;
    margin-bottom: 1rem;

    span {
      color: #0b73ba;
    }

    .amount {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
  }

  .calculation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .item-box {
      width: 100%;
    }

    input {
      font-size: 1.5rem;
    }

    @media (min-width: 1150px) {
      flex-direction: row;
      align-items: center;
      gap: 0.25rem;
    }

    .math-symbol {
      font-size: 1.5rem;
    }

  }
}
</style>