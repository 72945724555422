<script setup>
import {computed, inject} from 'vue'
import {useStepStore} from "./stores/steps";

const stepStore = useStepStore()
const goToStep = inject('goToStep')

const props = defineProps({
  steps: Array,
  count: Number
})

const currentIndex = computed(() => {
  return props.steps.findIndex(step => step.key === stepStore.currentStepKey)
})
</script>

<template>
  <div class="wlf-controls">
    <div class="g-container wlf-controls__container">
      <div class="wlf-nav" role="navigation" aria-labelledby="steps-nav">
        <h2 :id="`${count}-steps-nav`" class="sr-only">Step Navigation</h2>
        <div
            class="wlf-nav__bar"
            role="progressbar"
            :aria-valuenow="currentIndex"
            :aria-valuemin="0"
            :aria-valuemax="steps.length - 1"
            :aria-labelledby="`${count}-steps-nav`"
        >
          <div class="wlf-nav__bar__inner"></div>
        </div>
        <template v-for="(step, index) in steps" :key="index">
          <button
              class="wlf-nav__level"
              @click="goToStep(step.key)"
              :aria-label="index < currentIndex ? `Go to ${step.name}` : `Step ${index + 1}: ${step.name}`"
              :aria-current="step.key === stepStore.currentStepKey ? 'step' : null"
          >
            <span
                class="wlf-nav__dot wlf-nav__dot--large"
                :class="{
                is_current: step.key === stepStore.currentStepKey,
                is_past: index < currentIndex
              }"
            >
              <span class="wlf-nav__dot__inner">
                <component :is="step.icon" class="h-icon"></component>
              </span>
            </span>
            <span class="wlf-nav__text" v-text="step.name"></span>
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped>
.wlf-controls {
  margin-bottom: 2rem;
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
}
.wlf-controls__container,
.wlf-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.wlf-nav {
  flex: 1 1 0;
  margin: 25px clamp(15px, 2.5vw, 40px);
}

.wlf-nav__bar {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  height: 2px;
  background: #aec7ce;
}

.wlf-nav__bar__inner {
  transition: all 0.5s ease;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0px;
  background: var(--green);
}

.wlf-nav__level {
  position: relative;
  border: none;
  background: none;
  padding: 0;
}

.wlf-nav__text {
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  line-height: 150%;
  white-space: nowrap;
}

.wlf-nav__dot--large {
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 50%;
  background: var(--green);
  color: white;
  background: var(--white);
  color: var(--charcoal);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease;
}

.wlf-nav__dot__inner {
  color: inherit;
}

.wlf-nav__dot__inner svg {
  display: block;
}

.wlf-nav__dot--small {
  width: 14px;
  height: 14px;
  display: block;
  background: var(--green);
  background: #aec7ce;
  border-radius: 50%;
  border: none;
  appearance: none;
  transition: all 0.4s ease;
  transform: scale(1);
}

.wlf-nav__dot.is_past,
.wlf-nav__dot.is_current {
  background: var(--green);
  color: white;
}

.wlf-nav__dot.is_current {
}

.wlf-nav__dot {
  cursor: pointer;
}

.wlf-nav__dot--small.is_past:focus,
.wlf-nav__dot--small.is_past:hover {
  transform: scale(1.71);
}

.wlf-controls__direction {
  /* min-width: 150px; */
}

@media screen and (max-width: 900px) {
  .wlf-nav__dot--large {
    width: 38px;
    height: 38px;
  }

  .wlf-nav__dot--small {
    width: 12px;
    height: 12px;
  }
}

@media screen and (max-width: 800px) {
  .wlf-controls__container {
    flex-wrap: wrap;
  }

  .wlf-nav {
    width: 100%;
    order: 1;
    flex: auto;
    margin: 25px 0px 65px;
  }

  .wlf-controls__direction {
    order: 2;
    width: 50%;
  }

  .wlf-nav__dot--small {
    display: none;
  }

  .wlf-nav__dot--small.is_current_level {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .wlf-nav__dot--small {
    width: 10px;
    height: 10px;
  }

  .wlf-nav__dot--large {
    width: 34px;
    height: 34px;
  }

  .wlf-nav__text {
    display: none;
  }

  .is_current + .wlf-nav__text {
    display: block;
  }

  .wlf-nav__text {
    font-size: 12px;
  }
}

.wlf-nav__dot.is_current_level {
  animation: bubble 0.3s linear forward 0.3s;
}

@keyframes bubble {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>