<script setup>
import {useEntryDataStore} from './stores/entryData'
import axios from 'axios'
import qs from 'qs'
import {computed, ref, inject, nextTick} from 'vue'
import {useStepStore} from './stores/steps'
import {useAnalyticsCategory} from "./composables/useAnalyticsCategory";

const store = useEntryDataStore()
const stepStore = useStepStore()

const submitted = ref(false)
const error = ref(false)

let updateUrl = inject('updateUrl')

const props = defineProps({
  data: Object,
  submitLabel: String
})

const analytics = {
  category: ''
}

const submit = async () => {
  const category = useAnalyticsCategory(analytics.category)
  const formId = category.value.split('_')[1]
  window.dataLayer.push({
    event: 'form_start',
    event_name: 'form_start',
    event_category: category.value,
    event_label: '',
  })
  try {
    const data = {...store.form, action: 'get_started_submission'}
    const response = await axios.post(GET_STARTED.ajax_url, qs.stringify(data))
    if (response.data.success) {
      submitted.value = true
      store.hubspot.submitted = true
      window.dataLayer.push({
        event: 'hubspot_form_success',
        event_name: 'hubspot_form_success',
        event_category: category.value,
        event_label: 'quotesummary',
        form_id: formId
      })

      await nextTick(() => {
        const formElement = document.getElementById('submission-confirmation')
        if (formElement) {
          formElement.scrollIntoView({behavior: 'smooth', block: 'start'})
        }
      })
    }
  } catch (error) {
    error.value = true
    console.error(error)
  }
}

const invalidPhone = computed(() => /^(\d)\1{9}$/.test(store.form.phone))

const startNewQuote = () => {
  window.dataLayer.push({
    event: 'click',
    event_name: 'click',
    event_category: 'webtool_navigation',
    event_label: 'webtool_restart_button',
  })
  store.$reset()
  stepStore.$reset()

  updateUrl('get-started')
}

const finishTool = () => {
  window.dataLayer.push({
    event: 'webtool_finish_button_click',
    event_name: 'webtool_finish_button_click',
    event_category: 'webtool_navigation',
    event_label: 'webtool_finish_button',
  })
  store.$reset()
  stepStore.$reset()

  window.location.href = '/'
}

window.addEventListener('beforeunload', e => {
  // e.preventDefault();
  const category = useAnalyticsCategory(analytics.category)
  if (!submitted.value) {
    window.dataLayer.push({
      event: 'form_abandonment',
      event_name: 'form_abandonment',
      event_category: 'webtool_quotesummary',
      event_label: '',
    })
  }
})
</script>

<template>
  <div>
    <div v-if="error">
      <div>There was an error submitting your quote.</div>
      <button class="s-button" @click="error = false">Retry Submission</button>
    </div>
    <div v-if="submitted" id="submission-confirmation">
      <div>{{ data.confirmation_header }}</div>
      <div v-html="data.confirmation_content"></div>
      <div class="button-group">
        <button class="s-button" @click="startNewQuote">Start a New Quote</button>
        <button class="s-button--plain" @click="finishTool">Finished</button>
      </div>
    </div>
    <div v-else>
      <form class="summary-form" @submit.prevent="submit">
        <div>
          <h3 id="form-header">{{ data.header }}</h3>
          <div v-html="data.content"></div>
        </div>
        <div>
          <h3 id="name-question" class="question">What's your name?</h3>
          <div class="form-group">
            <div class="form-input">
              <label id="firstname-label" for="firstname" class="required">
                <span>First Name:</span>
              </label>
              <input id="firstname" name="firstname" placeholder="First Name" v-model="store.form.firstname"
                     required aria-required="true" aria-labelledby="firstname-label name-question">
            </div>
            <div class="form-input">
              <label id="lastname-label" for="lastname" class="required">
                <span>Last Name:</span>
              </label>
              <input id="lastname" name="lastname" placeholder="Last Name" v-model="store.form.lastname"
                     required aria-required="true" aria-labelledby="lastname-label name-question">
            </div>
          </div>
        </div>
        <div>
          <h3 id="province-question" class="question">What province do you live in?</h3>
          <p id="province-info">We are only licensed to provide insurance to residents of Ontario, Manitoba, Saskatchewan, Alberta, or BC.</p>
          <div class="form-group">
            <div class="form-input">
              <label id="province-label" for="province" class="required">Province</label>
              <select id="province" name="province" v-model="store.form.province"
                      required aria-required="true" aria-labelledby="province-label province-question" aria-describedby="province-info">
                <option value="" disabled selected>Select a province</option>
                <option value="Ontario">Ontario</option>
                <option value="Manitoba">Manitoba</option>
                <option value="Saskatchewan">Saskatchewan</option>
                <option value="Alberta">Alberta</option>
                <option value="British Columbia">British Columbia</option>
              </select>
            </div>
          </div>
        </div>
        <div>
          <h3 id="contact-question" class="question">Share your contact information so we can provide your quote:</h3>
          <div class="form-group">
            <div class="form-input">
              <label id="email-label" for="email" class="required">Email Address:</label>
              <input id="email" name="email" type="email" placeholder="Email Address"
                     required v-model="store.form.email" aria-required="true" aria-labelledby="email-label contact-question">
            </div>
            <div class="form-input">
              <label id="phone-label" for="phone">Phone Number:</label>
              <input id="phone" name="phone" type="tel" maxlength="10" placeholder="Phone Number"
                     v-model="store.form.phone" aria-labelledby="phone-label contact-question" aria-describedby="phone-error">
              <div v-if="invalidPhone" id="phone-error" class="invalid-input" aria-live="polite">Invalid phone number</div>
            </div>
          </div>
        </div>
        <div>
          <div id="disclaimer-text" v-html="data.disclaimer"></div>
          <div class="form-group">
            <div class="form-input checkbox">
              <input type="checkbox" id="email-consent" name="email-consent" v-model="store.form.caslconsent"
                     aria-labelledby="email-consent-label">
              <label id="email-consent-label" for="email-consent">
                I would like to receive Serenia Life Financial e-communications in the form of a monthly newsletter, company announcements, and more. (Not to worry, you can unsubscribe anytime.)
              </label>
            </div>
          </div>
        </div>
        <div>
          <button class="s-button" type="submit" :aria-label="props.submitLabel" aria-labelledby="form-header">
            {{ props.submitLabel }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped lang="scss">
.summary-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 4rem;

  .question {
    margin-bottom: 0.5rem;
  }
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem 0;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.form-input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;

  @media (min-width: 415px) {
    flex-direction: row;
    align-items: center;
  }

  &.checkbox {
    label {
      font-weight: 300;
    }
  }
}

input:not([type='checkbox']), select {
  min-height: 1.75rem;
  padding: 0 1rem;
  height: 2.5rem;
  border-radius: 3px;
  border: 1px solid #cbd6e2;
  background: #f5f8fa;

  ::placeholder {
    color: #cbd6e2;
  }

  &:focus {
    outline-offset: 2px;
    outline: var(--blue) solid 2px;
  }
}

input[type='checkbox'] {
  &:focus {
    outline-offset: 2px;
    outline: var(--blue) solid 2px;
  }
}

label {
  &.required:after {
    content: '*';
    color: #ff0000;
  }
}

.invalid-input {
  color: #c87872;
}
</style>