// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.question-title[data-v-ad6661a6] {
  text-align: center;
}
.v-quiz__inputs[data-v-ad6661a6] {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  margin: 1rem 0;
}
@media (min-width: 768px) {
.v-quiz__inputs[data-v-ad6661a6] {
      grid-template-columns: repeat(2, 1fr);
}
}
@media (min-width: 1150px) {
.v-quiz__inputs[data-v-ad6661a6] {
      grid-template-columns: repeat(4, 1fr);
}
}
.total-amount[data-v-ad6661a6] {
  padding: 1rem;
  border-radius: 0.75rem;
  color: white;
  background-color: var(--blue);
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
}
.total-amount span[data-v-ad6661a6] {
    color: white;
}
`, "",{"version":3,"sources":["webpack://./../assets/vue/DoTheMathQuestion.vue"],"names":[],"mappings":"AACA;EACE,kBAAkB;AAAA;AAGpB;EACE,aAAa;EACb,SAAS;EACT,0BAA0B;EAC1B,cAAc;AAAA;AAEd;AANF;MAOI,qCAAqC;AAAA;AAMxC;AAHC;AAVF;MAWI,qCAAqC;AAAA;AAExC;AAED;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,6BAA6B;EAC7B,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;AAAA;AAPpB;IAUI,YAAY;AAAA","sourcesContent":["\n.question-title {\n  text-align: center;\n}\n\n.v-quiz__inputs {\n  display: grid;\n  gap: 1rem;\n  grid-template-columns: 1fr;\n  margin: 1rem 0;\n\n  @media (min-width: 768px) {\n    grid-template-columns: repeat(2, 1fr);\n  }\n\n  @media (min-width: 1150px) {\n    grid-template-columns: repeat(4, 1fr);\n  }\n}\n\n.total-amount {\n  padding: 1rem;\n  border-radius: 0.75rem;\n  color: white;\n  background-color: var(--blue);\n  font-weight: bold;\n  font-size: 1.5rem;\n  text-align: center;\n\n  span {\n    color: white;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
