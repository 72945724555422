// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.summary-form[data-v-527f931b] {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 4rem;
}
.summary-form .question[data-v-527f931b] {
    margin-bottom: 0.5rem;
}
.form-group[data-v-527f931b] {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem 0;
}
@media (min-width: 768px) {
.form-group[data-v-527f931b] {
      flex-direction: row;
}
}
.form-input[data-v-527f931b] {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
}
@media (min-width: 415px) {
.form-input[data-v-527f931b] {
      flex-direction: row;
      align-items: center;
}
}
.form-input.checkbox label[data-v-527f931b] {
    font-weight: 300;
}
input[data-v-527f931b]:not([type='checkbox']), select[data-v-527f931b] {
  min-height: 1.75rem;
  padding: 0 1rem;
  height: 2.5rem;
  border-radius: 3px;
  border: 1px solid #cbd6e2;
  background: #f5f8fa;
}
input[data-v-527f931b]:not([type='checkbox']) ::placeholder, select[data-v-527f931b] ::placeholder {
    color: #cbd6e2;
}
input[data-v-527f931b]:not([type='checkbox']):focus, select[data-v-527f931b]:focus {
    outline-offset: 2px;
    outline: var(--blue) solid 2px;
}
input[type='checkbox'][data-v-527f931b]:focus {
  outline-offset: 2px;
  outline: var(--blue) solid 2px;
}
label.required[data-v-527f931b]:after {
  content: '*';
  color: #ff0000;
}
.invalid-input[data-v-527f931b] {
  color: #c87872;
}
`, "",{"version":3,"sources":["webpack://./../assets/vue/QuoteEmailForm.vue"],"names":[],"mappings":"AACA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,mBAAmB;AAAA;AAJrB;IAOI,qBAAqB;AAAA;AAIzB;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,iBAAiB;AAAA;AAEjB;AANF;MAOI,mBAAmB;AAAA;AAEtB;AAED;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,uBAAuB;AAAA;AAEvB;AANF;MAOI,mBAAmB;MACnB,mBAAmB;AAAA;AAQtB;AAhBD;IAaM,gBAAgB;AAAA;AAKtB;EACE,mBAAmB;EACnB,eAAe;EACf,cAAc;EACd,kBAAkB;EAClB,yBAAyB;EACzB,mBAAmB;AAAA;AANrB;IASI,cAAc;AAAA;AATlB;IAaI,mBAAmB;IACnB,8BAA8B;AAAA;AAIlC;EAEI,mBAAmB;EACnB,8BAA8B;AAAA;AAIlC;EAEI,YAAY;EACZ,cAAc;AAAA;AAIlB;EACE,cAAc;AAAA","sourcesContent":["\n.summary-form {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n  margin-bottom: 4rem;\n\n  .question {\n    margin-bottom: 0.5rem;\n  }\n}\n\n.form-group {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n  padding: 0.5rem 0;\n\n  @media (min-width: 768px) {\n    flex-direction: row;\n  }\n}\n\n.form-input {\n  display: flex;\n  flex-direction: column;\n  gap: 0.5rem;\n  align-items: flex-start;\n\n  @media (min-width: 415px) {\n    flex-direction: row;\n    align-items: center;\n  }\n\n  &.checkbox {\n    label {\n      font-weight: 300;\n    }\n  }\n}\n\ninput:not([type='checkbox']), select {\n  min-height: 1.75rem;\n  padding: 0 1rem;\n  height: 2.5rem;\n  border-radius: 3px;\n  border: 1px solid #cbd6e2;\n  background: #f5f8fa;\n\n  ::placeholder {\n    color: #cbd6e2;\n  }\n\n  &:focus {\n    outline-offset: 2px;\n    outline: var(--blue) solid 2px;\n  }\n}\n\ninput[type='checkbox'] {\n  &:focus {\n    outline-offset: 2px;\n    outline: var(--blue) solid 2px;\n  }\n}\n\nlabel {\n  &.required:after {\n    content: '*';\n    color: #ff0000;\n  }\n}\n\n.invalid-input {\n  color: #c87872;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
