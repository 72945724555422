<script setup>
const props = defineProps({
  section: String
})

const disclaimers = TOOL_DISCLAIMERS[props.section]
</script>

<template>
  <div class="disclaimers-container">
    <div v-if="disclaimers" class="disclaimers">
      <p v-html="disclaimers.disclaimers"></p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.disclaimers {
  :deep(p) {
    font-size: 10px;
  }
  margin-top: 2rem;
}
</style>