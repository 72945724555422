<script setup>
import QuoteEmailForm from './QuoteEmailForm.vue'
import {computed, onMounted, ref} from 'vue'
import {useAnalyticsCategory} from './composables/useAnalyticsCategory'
import {useEntryDataStore} from './stores/entryData'
import Disclaimers from "./Disclaimers.vue";

const data = QUOTE.summary;

const store = useEntryDataStore()

const emailFormData = ref(null)

const disclaimersSection = computed(() => {
  if (store.form.hubspot_form === 'whole_life') {
    return 'whole_life_email_form'
  }
  if (store.form.hubspot_form === '20_pay_whole_life') {
    return '20pay_whole_life_email_form'
  }
  return null
})

onMounted(() => {
  const category = useAnalyticsCategory('webtool_wholelife')
  window.dataLayer.push({
    event: 'accordion_opened',
    event_name: 'accordion_opened',
    event_category: category.value,
    event_label: '',
  })
  window.dataLayer.push({
    event: 'page_view',
    event_name: 'page_view',
    event_category: category.value,
    event_label: '',
  })
  if (store.form.product_name && store.form.product_name.includes('20')) {
    emailFormData.value = data.whole_life_20_pay
  } else {
    emailFormData.value = data.whole_life
  }
})

</script>

<template>
  <div>
    <QuoteEmailForm v-if="emailFormData" :data="emailFormData" :submit-label="`Book a Meeting`"/>
    <Disclaimers v-if="disclaimersSection" :section="disclaimersSection"/>
    <slot name="navigation"/>
  </div>
</template>

<style>
</style>