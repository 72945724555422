<script setup>
import { ref } from 'vue'

import contact from '../../../images/contact.png'
import contactHover from '../../../images/contact-hover.png'

const isHovered = ref(false)
</script>

<template>
  <img
      :src="isHovered ? contactHover : contact"
      @mouseover="isHovered = true"
      @mouseleave="isHovered = false"
      alt="Contact Icon"
      class="h-icon-image"
  />
</template>

<style scoped lang="scss">

</style>
