// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.disclaimers[data-v-37545a11] {
  margin-top: 2rem;
}
.disclaimers[data-v-37545a11] p {
    font-size: 10px;
}
`, "",{"version":3,"sources":["webpack://./../assets/vue/Disclaimers.vue"],"names":[],"mappings":"AACA;EAIE,gBAAgB;AAAA;AAJlB;IAEI,eAAe;AAAA","sourcesContent":["\n.disclaimers {\n  :deep(p) {\n    font-size: 10px;\n  }\n  margin-top: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
