<script setup>
import {useEntryDataStore} from './stores/entryData'
import {computed, inject, markRaw, ref} from 'vue'
import Dialog from './components/Dialog.vue'

import {
  IconBenefits,
  IconPromotion,
  IconContact,
  IconProducts
} from './components/icons'

import {
  BoltIcon,
  DocumentCheckIcon,
  UserGroupIcon,
  ChatBubbleLeftRightIcon,
  XMarkIcon,
  FaceSmileIcon,
  ChevronDownIcon,
  ChevronUpIcon
} from '@heroicons/vue/24/outline'

const data = TOOL_FOOTER.data
const store = useEntryDataStore()
const changePath = inject('changePath')

const dialogs = ref([
  {
    open: false,
    icon: markRaw(IconProducts),
    title: data.recommendations.title,
    description: 'Recommended Products',
    mobile_title: data.recommendations.mobile_title,
  },
  {
    open: false,
    icon: markRaw(IconBenefits),
    title: data.memberBenefits.title,
    description: data.memberBenefits.description,
    mobile_title: data.memberBenefits.mobile_title,
  },
  {
    open: false,
    icon: markRaw(IconPromotion),
    title: data.promotions.title,
    description: data.promotions.description,
    mobile_title: data.promotions.mobile_title,
  }
])

const showRecommendations = computed(() => {
  return store.needsAssessment.productRecommendations.length > 0
})

const footerExpanded = computed(() => {
  return store.footer.shown
})

function switchToAdvisor() {
  window.dataLayer.push({
    event: 'click',
    event_name: 'click',
    event_category: 'webtool_advisorcontact',
    event_label: 'webtool_advisor_button'
  })
  changePath('advisor')
}

function toggleFooter() {
  window.dataLayer.push({
    event: 'click',
    event_name: 'click',
    event_category: 'webtool_navigation',
    event_label: 'webtool_footer_toggle_button',
  })
  store.footer.shown = !store.footer.shown
}
</script>

<template>
  <div class="tool-footer-container" :class="{ collapsed: !footerExpanded }">
    <div class="g-container--wide">
      <div class="footer-controls">
        <button class="s-button--plain toggle-footer-button"
                @click="toggleFooter"
                v-if="!footerExpanded"
                :aria-expanded="footerExpanded"
                :aria-label="footerExpanded ? 'Collapse footer' : 'Expand footer'">
          <span v-if="footerExpanded">{{ data.general.toggle_button_hide_text }}<ChevronUpIcon class="h-icon"/></span>
          <span v-else>{{ data.general.toggle_button_show_text }}<ChevronDownIcon class="h-icon"/></span>
        </button>

        <button v-if="!footerExpanded" class="s-button advisor-button"
                @click="switchToAdvisor"
                aria-label="Speak to an Advisor">
          {{ data.advisor.button_text }}
        </button>
      </div>

      <div class="tool-footer desktop" v-if="footerExpanded" :class="{ 'has-recommendations': showRecommendations }">
        <div class="footer-promo" v-if="showRecommendations">
          <div class="title" v-html="data.recommendations.title"></div>
          <div class="recommendations" v-for="(recommendation, index) in store.needsAssessment.productRecommendations"
               :key="index">
            <div>{{ recommendation.product_title }}</div>
          </div>
        </div>
        <div class="footer-promo" v-if="data.memberBenefits.title">
          <div class="title" v-html="data.memberBenefits.title"></div>
          <p v-html="data.memberBenefits.description"></p>
        </div>
        <div class="footer-promo" v-if="data.promotions.title">
          <div class="title" v-html="data.promotions.title"></div>
          <p v-html="data.promotions.description"></p>
        </div>
        <div v-if="data.advisor" class="advisor-section">
          <button class="s-button" @click="switchToAdvisor" aria-label="Speak to an Advisor">
            {{ data.advisor.button_text }}
          </button>

          <button class="tf-button close" @click="toggleFooter" name="Close footer" aria-label="Close footer">
            <XMarkIcon class="h-icon"/>
          </button>
        </div>
      </div>

      <div class="tool-footer mobile" v-if="footerExpanded">
        <template v-for="(dialog, index) in dialogs" :key="index">
          <div v-if="(index === 0 && showRecommendations) || index > 0" class="tf-button-wrap">
            <button
                @click="dialog.open = !dialog.open"
                class="tf-button"
                :class="{ active: dialog.open }"
                :aria-expanded="dialog.open.toString()"
                :aria-controls="'dialog-' + index"
                :aria-label="dialog.title"
            >
              <component :is="dialog.icon" class="h-icon"/>
            </button>
            <span>{{dialog.mobile_title}}</span>
          </div>
        </template>
        <div class="tf-button-wrap">
          <button class="tf-button" @click="switchToAdvisor" :aria-label="data.advisor.mobile_title">
            <IconContact/>
          </button>
          <span>{{data.advisor.mobile_title}}</span>
        </div>
        <button class="tf-button close" @click="toggleFooter" name="Close footer" aria-label="Close footer">
          <XMarkIcon class="h-icon"/>
        </button>
      </div>
      <Dialog v-for="(dialog, index) in dialogs"
              :key="index"
              :open="dialog.open"
              class="dialog"
              role="dialog"
              :id="'dialog-' + index"
              :aria-labelledby="'dialog-title-' + index"
              :aria-describedby="dialog.description ? 'dialog-desc-' + index : null"
              @keydown.esc="dialog.open = false">
        <div class="dialog-content" tabindex="-1">
          <div class="dialog-title-wrap">
            <div class="dialog-title" :id="'dialog-title-' + index">
              <component :is="dialog.icon" class="h-icon"/>
              <span v-html="dialog.title"></span>
            </div>
            <button @click="dialog.open = false" aria-label="Close dialog">
              <XMarkIcon class="h-icon"/>
            </button>
          </div>
          <div v-if="index === 0" class="recommendations"
               v-for="(recommendation, recIndex) in store.needsAssessment.productRecommendations" :key="recIndex">
            <div>{{ recommendation.product_title }}</div>
          </div>
          <div v-else>
            <p v-if="dialog.description" :id="'dialog-desc-' + index" v-html="dialog.description"></p>
          </div>
          <button class="s-button--plain dialog-close" @click="dialog.open = false">Close Window</button>
        </div>
      </Dialog>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tool-footer-container {
  background: var(--blue-light);
  position: sticky;
  bottom: 0;
  width: 100%;
  padding: 2rem 0;
  z-index: 10001;
  transition: transform 0.3s ease-in-out;
}

.tool-footer-container.collapsed {
  transform: translateY(100%);
}


.tool-footer {
  justify-content: center;
  gap: 2rem;
  font-size: 0.85rem;

  &.desktop {
    display: none;
  }

  &.mobile {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
  }

  @media (min-width: 921px) {
    &.desktop {
      display: flex;
    }
    &.mobile {
      display: none;
    }
  }

  &.has-recommendations {
    justify-content: flex-end;
  }

  > div {
    flex-basis: 25%;

    > span {
      display: block;
      margin-bottom: 0.5rem;
    }
  }

  :deep(p) {
    font-size: 0.85rem;
  }

  .advisor-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1rem;
  }
}

.footer-promo {
  padding: 1rem;
  background-color: var(--grey);
  border-radius: 0.75rem;

  .title {
    //:deep(p) {
    //  margin-bottom: 0;
    //}
  }
}

.tf-button {
  background: none;
  padding: 0;
  border: none;
  border-radius: 50%;
  //display: flex;
  //justify-content: center;
  //align-items: center;
  //height: 3rem;
  //width: 3rem;
  cursor: pointer;
  //transition: 0.4s all;

  &.close {
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    width: 2rem;
    flex-shrink: 0;

    @media (min-width: 512px) {
      height: 3.5rem;
      width: 3.5rem;
    }

    &:hover {
      background: var(--green);
      color: white;
    }
  }

  //&.active,
  //&:hover {
  //  background: var(--green);
  //  color: white;
  //}
}

.tf-button-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0.25rem;

  span {
    background-color: white;
    font-size: 0.5rem;
    white-space: nowrap;
    border-radius: 0.75rem;
    margin: 0;
    padding: 0.15rem 0.25rem;
  }
}

.recommendations {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.dialog {
  border: none;
  border-radius: 0.75rem;
  width: 75vw;

  .dialog-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .dialog-title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dialog-title {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      font-size: 1.25rem;

      :deep(p) {
        margin: 0;
      }
    }

    button {
      border: none;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2rem;
      width: 2rem;
      cursor: pointer;
    }
  }

  .dialog-close {
    justify-content: center;
  }
}

.footer-controls {
  position: absolute;
  top: -6rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 1rem; // Adjust spacing as needed

  @media (min-width: 415px) {
    top: -3rem;
    flex-direction: row;
    gap: 1rem;
  }

  .advisor-button,
  .toggle-footer-button {
    border: none;
    //padding: 0.375rem 0.625rem;
    border-radius: 9999px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background 0.3s ease;
    white-space: nowrap;

    span {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 0.9rem;
      color: white;
    }

    .h-icon {
      height: 17px;
    }
  }

  .toggle-footer-button {
    background: var(--blue);
  }
}


</style>
