<template>
  <div>
    <div class="question-body">
      <h3>{{ question.title }}</h3>
      <div class="question-text" v-html="(question) ? question.text : null"></div>
    </div>
    <div class="v-quiz__actions" :class="'v-quiz__actions--' + question.question_key">
      <get-started-question-answers
          :question="question"
          v-model="store.form[question.question_key]"
          @answer="handleIfProductRecommendation($event)"
      />
    </div>
    <div v-if="(question) && question.disclaimer != ''" class="question-footer">
      <div class="question-disclaimer" v-html="question.disclaimer"></div>
    </div>
<!--    <div v-if="productRecommendation">-->
<!--      <p>Whole Life is recommended, do you wan to proceed with a quote for whole life or term?</p>-->
<!--      <div class="whole-life-options">-->
<!--        <button class="s-button&#45;&#45;plain" @click="handleWholeLife(true)">Yes, proceed with Whole Life</button>-->
<!--        <button class="s-button&#45;&#45;plain" @click="handleWholeLife(false)">No, I want to get a quote</button>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script setup>
import GetStartedQuestionAnswers from './GetStartedQuestionAnswers.vue'
import {useEntryDataStore} from './stores/entryData'
import {ref} from 'vue'

const emit = defineEmits(['answer', 'complete'])

const props = defineProps({
  question: {
    type: Object,
    required: true
  },
  index: Number
})

const store = useEntryDataStore()

const productRecommendation = ref(false)
const model = ref(null)

const handleWholeLife = (skipToWholeLife) => {
  if (skipToWholeLife) {
    store.getStarted.productTypeSelected = 'whole_life_product_in_mind'
    store.form.get_started_path_selection = 'whole_life_product_in_mind'
    emit('complete')
  } else {
    productRecommendation.value = false
    const resultToEmit = {
      answer: model.value,
      index: props.index,
      question_key: props.question.question_key
    }
    emit('answer', resultToEmit)
  }
}

function handleIfProductRecommendation(answer) {
  // Reset the toggle for the product recommendation because the answer can be toggled off/null
  productRecommendation.value = false
  model.value = answer
  // if (answer && answer.related_product) {
  //   answer.related_product.some(related_product => {
  //     if (related_product.product_title.includes('Whole Life')) {
  //       productRecommendation.value = true
  //       return true
  //     }
  //   })
  //   if (productRecommendation.value) {
  //     // Emit null to reset the question index
  //     // Prevents toggling between answers and condition where the whole life product
  //     // recommendation would appear above another question's follow up
  //     const resultToEmit = {
  //       answer: null,
  //       index: props.index,
  //       question_key: props.question.question_key
  //     }
  //     emit('answer', resultToEmit)
  //     return
  //   }
  // }

  const resultToEmit = {
    answer: answer,
    index: props.index,
    question_key: props.question.question_key
  }
  emit('answer', resultToEmit)
}
</script>

<style lang="scss" scoped>
.question-title {
  font-size: 1.75rem;
  color: var(--color__heading-text);
  margin-bottom: 1rem;
}

.question-text {
  margin: 0 auto;
}

.question-footer {
  margin-top: 40px;
}

.v-quiz__actions {
  margin: 0 auto;
  width: 100%;
}
</style>
