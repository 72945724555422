<template>
  <div class="wlf-answers">
    <button
        class="wlf-answer text-h4"
        :class="{
				'is-active': isSelected(
					answer.value ? answer.value : answer.text
				),
				'wlf-answer--small': question.answers.length > 10
			}"
        :data-first-element="answerIndex == 0 ? 'firstElement' : null"
        :ref="answerIndex == 0 ? 'firstElement' : null"
        v-for="(answer, answerIndex) in question.answers"
        v-bind:key="answer.text + '-' + answer.value"
        @click="toggleAnswer(answer)"
        v-text="answer.text ? answer.text : answer.title"
    ></button>
  </div>
</template>

<script setup>
import {useEntryDataStore} from './stores/entryData'
import {ref} from 'vue'

const props = defineProps({
  question: {
    type: Object,
    required: true
  }
})

const store = useEntryDataStore()

const emits = defineEmits(['answer'])

const model = defineModel()

const analytics = {
  category: 'webtool_needs'
}

const isSelected = (option) => {
  if (!model.value) {
    return false
  }
  return model.value === option
}

const toggleAnswer = (answer) => {
  if (!store.getStarted.questionInitialEvent) {
    window.dataLayer.push({
      event: 'accordion_started',
      event_name: 'accordion_started',
      event_category: analytics.category,
      event_label: '',
    })
    store.getStarted.questionInitialEvent = true
  }
  window.dataLayer.push({
    event: 'accordion_engagement',
    event_name: 'accordion_engagement',
    event_category: analytics.category,
    event_label: props.question.title
  })

  if (store.form[props.question.question_key] && store.form[props.question.question_key] === answer.value) {
    // If we have a value and answer value is the same, set it to be null
    store.form[props.question.question_key] = null
    emits('answer', null)
    return
  }
  store.form[props.question.question_key] = answer.value
  emits('answer', answer)
}
</script>

<style lang="scss" scoped>
.wlf-answers {
  margin: 35px 0;
}

.wlf-answer {
  background: white;
  padding: 27px 35px;
  border-radius: 0.75rem;
  cursor: pointer;
  margin: 15px;
  min-width: 200px;
  transition: all 0.4s ease;
  font-weight: 300;
  border: 1px solid rgba(30, 41, 59, 0.10);

  &:hover,
  &:focus {
    border: 1px solid transparent;
    outline-offset: 2px;
    outline: var(--blue) solid 2px;
  }

  //&:focus {
  //  outline-offset: 2px;
  //  outline: var(--blue) solid 2px;
  //}
}

.wlf-answer.is-active {
  color: white;
  background: var(--blue);
}



.wlf-answer--small {
  font-size: 17px;
  margin: 7px;
  padding: 10px 20px;
  min-width: 0;
}

/* Question Specific Styling */
//.v-quiz__actions--goals
.wlf-answers {
  display: flex;
  flex-wrap: wrap;
}

//.v-quiz__actions--goals
.wlf-answer {
  font-size: 16px;
  width: calc(33% - 30px);
  min-width: 0;
}

@media screen and (max-width: 900px) {
  .wlf-answers {
    margin: 15px 0 0;
  }

  .wlf-answer {
    width: 100%;
    margin: 10px 0;
  }

  .v-quiz__actions--goals .wlf-answer {
    font-size: 16px;
    margin: 10px;
    width: calc(50% - 20px);
  }
}

@media screen and (max-width: 768px) {
  .v-quiz__actions--goals .wlf-answer {
    font-size: 16px;
    margin: 5px 0;
    width: calc(100%);
  }
}
</style>
