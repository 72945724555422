// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quote-questions {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.date-question {
  display: flex;
  justify-content: center;
}
@media (min-width: 768px) {
.date-question {
      display: block;
}
}
.dp__main {
  width: initial;
}
.dp__input_icon,
.dp--clear-btn {
  color: #414042;
}
.dp__input,
input[type="date"] {
  border: 1px solid rgba(30, 41, 59, 0.1);
  padding: 1rem;
  margin: 0.5rem;
  width: 15rem;
  text-align: center;
  color: var(--blue);
  border-radius: 0.5rem;
  text-transform: uppercase;
  transition: all .4s ease;
  cursor: pointer;
}
.dp__input:hover, .dp__input:focus,
  input[type="date"]:hover,
  input[type="date"]:focus {
    border: 1px solid transparent;
    border-color: transparent !important;
    outline-offset: 2px;
    outline: var(--blue) solid 2px;
}
.text-red {
  color: #c87872;
}
.error-banner {
  padding: 1rem;
  border-radius: 0.75rem;
  border: 1px solid #c87872;
  color: #c87872;
  margin: 1rem 0;
  display: inline-block;
}
`, "",{"version":3,"sources":["webpack://./../assets/vue/Quote.vue"],"names":[],"mappings":"AACA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AAAA;AAGX;EACE,aAAa;EACb,uBAAuB;AAAA;AAEvB;AAJF;MAKI,cAAc;AAAA;AAEjB;AAED;EACE,cAAc;AAAA;AAGhB;;EAEE,cAAc;AAAA;AAGhB;;EAEE,uCAAwC;EACxC,aAAa;EACb,cAAc;EACd,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,qBAAqB;EACrB,yBAAyB;EACzB,wBAAwB;EACxB,eAAe;AAAA;AAXjB;;;IAeI,6BAA6B;IAC7B,oCAAoC;IACpC,mBAAmB;IACnB,8BAA8B;AAAA;AAIlC;EACE,cAAc;AAAA;AAGhB;EACE,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,cAAc;EACd,cAAc;EACd,qBAAqB;AAAA","sourcesContent":["\n.quote-questions {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n\n.date-question {\n  display: flex;\n  justify-content: center;\n\n  @media (min-width: 768px) {\n    display: block;\n  }\n}\n\n.dp__main {\n  width: initial;\n}\n\n.dp__input_icon,\n.dp--clear-btn {\n  color: #414042;\n}\n\n.dp__input,\ninput[type=\"date\"] {\n  border: 1px solid rgba(30, 41, 59, 0.10);\n  padding: 1rem;\n  margin: 0.5rem;\n  width: 15rem;\n  text-align: center;\n  color: var(--blue);\n  border-radius: 0.5rem;\n  text-transform: uppercase;\n  transition: all .4s ease;\n  cursor: pointer;\n\n  &:hover,\n  &:focus {\n    border: 1px solid transparent;\n    border-color: transparent !important;\n    outline-offset: 2px;\n    outline: var(--blue) solid 2px;\n  }\n}\n\n.text-red {\n  color: #c87872;\n}\n\n.error-banner {\n  padding: 1rem;\n  border-radius: 0.75rem;\n  border: 1px solid #c87872;\n  color: #c87872;\n  margin: 1rem 0;\n  display: inline-block;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
