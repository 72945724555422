<script setup>
import {computed, provide, watch, onMounted, shallowRef, nextTick} from 'vue'
import ToolNavigation from './ToolNavigation.vue'
import ToolFooter from './ToolFooter.vue'
import GetStartedEntryPoint from './GetStartedEntryPoint.vue'
import GetStartedQuiz from './GetStartedQuiz.vue'
import ProductRecommendations from './ProductRecommendations.vue'
import Quote from './Quote.vue'
import DoTheMathQuiz from './DoTheMathQuiz.vue'
import TermQuote from './TermQuote.vue'
import {useEntryDataStore} from './stores/entryData'
import {useStepStore} from './stores/steps'
import {
  UserIcon,
  CalculatorIcon,
  DocumentIcon,
  CheckBadgeIcon,
  CheckCircleIcon,
  DocumentCheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  MagnifyingGlassIcon,
  ArrowPathIcon
} from '@heroicons/vue/24/outline'

import {
  IconTime
} from './components/icons'

import WholeLifeQuote from './WholeLifeQuote.vue'
import AdvisorQuote from './AdvisorQuote.vue'
import Disclaimers from "./Disclaimers.vue";

const store = useEntryDataStore()
const stepStore = useStepStore()

// store.$reset()
// stepStore.$reset()

const notSureSteps = computed(() => {
  const summaryName = store.summary.complete ? 'Summary - Email' : 'Summary'
  let stepList = [
    {key: 'get-started', name: 'Get Started', header: 'Get Started', component: GetStartedEntryPoint, icon: UserIcon},
    {
      key: 'product-needs',
      name: 'Product Needs',
      header: null,
      component: GetStartedQuiz,
      icon: MagnifyingGlassIcon
    },
  ]
  if (store.needsAssessment.productRecommendations.length) {
    stepList.push({
      key: 'product-recommendations',
      name: 'Product Recommendations',
      header: null,
      component: ProductRecommendations,
      icon: DocumentCheckIcon
    })
  }
  stepList.push({
    key: 'do-the-math',
    name: 'Calculate Needs',
    header: null,
    component: DoTheMathQuiz,
    icon: CalculatorIcon
  })
  stepList.push({key: 'quote', name: 'Quote', header: null, component: Quote, icon: DocumentIcon})
  stepList.push({key: 'summary', name: summaryName, header: 'Summary', component: TermQuote, icon: CheckBadgeIcon})
  return stepList
})

const termSteps = computed(() => {
  const summaryName = store.summary.complete ? 'Summary - Email' : 'Summary'
  return [
    {key: 'get-started', name: 'Get Started', header: 'Get Started', component: GetStartedEntryPoint, icon: UserIcon},
    {key: 'do-the-math', name: 'Calculate Needs', header: null, component: DoTheMathQuiz, icon: CalculatorIcon},
    {key: 'quote', name: 'Quote', header: null, component: Quote, icon: DocumentIcon},
    {key: 'summary', name: summaryName, header: 'Summary', component: TermQuote, icon: CheckBadgeIcon}
  ]
})

const wholeLifeSteps = computed(() => {
  let getStartedName = 'Get Started'
  if (store.form.quote_product_selection) {
    if (store.form.quote_product_selection.includes('Whole')) {
      getStartedName += ' - Whole Life'
    } else if (store.form.quote_product_selection.includes('100')) {
      getStartedName += ' - Term to 100'
    } else if (store.form.quote_product_selection.includes('20')) {
      getStartedName += ' - 20-Pay Whole Life'
    }
  }
  let requestQuoteName = 'Request Quote'
  if (store.form.quote_product_selection) {
    if (store.form.quote_product_selection.includes('Whole')) {
      requestQuoteName += ' - Whole Life'
    } else if (store.form.quote_product_selection.includes('100')) {
      requestQuoteName += ' - Term to 100'
    } else if (store.form.quote_product_selection.includes('20')) {
      requestQuoteName += ' - 20-Pay Whole Life'
    }
  }
  return [
    {key: 'get-started', name: getStartedName, header: getStartedName, component: GetStartedEntryPoint, icon: UserIcon},
    {
      key: 'request-quote',
      name: requestQuoteName,
      header: requestQuoteName,
      component: WholeLifeQuote,
      icon: CheckBadgeIcon
    }
  ]
})

const advisorSteps = computed(() => {
  return [
    {key: 'get-started', name: 'Get Started', header: null, component: GetStartedEntryPoint, icon: UserIcon},
    {key: 'talk-to-an-advisor', name: 'Talk to an Advisor', header: null, component: AdvisorQuote, icon: CheckBadgeIcon}
  ]
})

const steps = shallowRef(notSureSteps.value)

const stepMap = {
  'product-needs': notSureSteps.value,
  'do-the-math': notSureSteps.value,
  'quote': notSureSteps.value,
  'request-quote': wholeLifeSteps.value,
  'talk-to-an-advisor': advisorSteps.value
}

const currentStepKey = computed(() => stepStore.currentStepKey)
const currentStepIndex = computed(() => steps.value.findIndex(step => step.key === currentStepKey.value))
const isStepComplete = computed(() => stepStore.completedSteps.includes(currentStepKey.value))

const updateUrl = (key) => {
  const newUrl = new URL(window.location)
  newUrl.searchParams.set('step', key)
  window.history.pushState({}, '', newUrl)
}

const goToStep = (key) => {
  const step = steps.value.find(s => s.key === key)
  if (!step) return
  if (key === 'product-recommendations' && !stepStore.completedSteps.includes('product-needs')) {
    return
  }
  if (key === 'summary' && !store.quote.complete) {
    return
  }
  // if the tool is finished or the person is going back to get started from the whole life forms
  if (store.hubspot.submitted || (key === 'get-started' && currentStepKey.value === 'request-quote')) {
    reset(false)
  }

  // if (stepStore.completedSteps.includes(currentStepKey.value) || steps.value.findIndex(s => s.key === key) <= currentStepIndex.value) {
  stepStore.currentStepKey = key
  // }
  updateUrl(key)
}

const goToNextStep = () => {
  const index = steps.value.findIndex(step => step.key === stepStore.currentStepKey)
  if (index < steps.value.length - 1) {
    const nextStep = steps.value[index + 1]

    if (nextStep.key === 'product-recommendations') {
      window.dataLayer.push({
        event: 'accordion_completed',
        event_name: 'accordion_completed',
        event_category: 'webtool_needs',
        event_label: '',
      })
      window.dataLayer.push({
        event: 'accordion_started',
        event_name: 'accordion_started',
        event_category: 'webtool_productrecommendation',
        event_label: '',
      })
    }

    if (nextStep.key === 'do-the-math' && store.needsAssessment.productRecommendations.length > 0 && store.needsAssessment.recommendationComplete) {
      if (!stepStore.completedSteps.includes(currentStepKey.value)) {
        stepStore.completedSteps.push(currentStepKey.value)
      }
    }
    // Allow advancing if the next step is "Calculate Needs" or the current step is complete
    if (nextStep.key === 'summary' && !store.quote.complete) {
      return
    }
    // Handle when a Whole Life product is recommended during the not sure pathway
    if (store.form.get_started_path_selection === 'whole_life_product_in_mind' && nextStep.key === 'do-the-math') {
      changePath('whole_life')
      goToStep('request-quote')
      store.form.hubspot_form = 'whole_life'
      return
    }
    if (store.form.quote_product_selection && store.form.quote_product_selection.includes('100') && nextStep.key === 'do-the-math') {
      goToStep('quote')
      return
    }
    if (store.form.quote_product_selection && (nextStep.key === 'summary' || currentStepKey.value === 'product-recommendations')) {
      const product = store.form.quote_product_selection

      let form = null
      let changeToWholeLife = false
      if (product.includes('20Pay')) {
        form = '20_pay_whole_life'
        changeToWholeLife = true
      } else if (product.includes('WholeLife')) {
        form = 'whole_life'
        changeToWholeLife = true
      }
      if (changeToWholeLife) {
        if (currentStepKey.value === 'product-recommendations') {
          stepStore.completedSteps.push('product-needs')
        }
        changePath('whole_life')
        store.form.hubspot_form = form
        goToStep('request-quote')
        return
      }
    }
    const wholeLifePaths = ['whole_life_product_in_mind', 'term_100_life_product_in_mind']
    if (wholeLifePaths.includes(store.form.get_started_path_selection) && nextStep.key === 'request-quote') {
      if (store.form.get_started_path_selection === 'term_100_life_product_in_mind') {
        changePath('term')
        goToStep('quote')
      } else {
        changePath('whole_life')
        goToStep('request-quote')
      }
      return
    }

    stepStore.currentStepKey = nextStep.key
    updateUrl(nextStep.key)
  }
}

const goToPrevStep = () => {
  const index = currentStepIndex.value
  if (currentStepKey.value === 'request-quote') {
    store.$reset()
    stepStore.$reset()
    updateUrl('get-started')
    changePath('term')
    return
  }
  if (index > 0) {
    stepStore.currentStepKey = steps.value[index - 1].key
  }
}

const markStepComplete = () => {
  if (!stepStore.completedSteps.includes(currentStepKey.value)) {
    stepStore.completedSteps.push(currentStepKey.value)
  }
  if (currentStepKey.value === 'product-needs') {
    return
  }
  goToNextStep()
}

const isNextButtonDisabled = computed(() => {
  // return
  // if (currentStepKey.value === 'do-the-math') {
  //   return false
  // }
  // return !isStepComplete.value
})

const changePath = (path) => {
  if (path.includes('not_sure')) {
    steps.value = notSureSteps.value
  }
  if (path.includes('term')) {
    steps.value = termSteps.value
  }
  if (path.includes('whole_life')) {
    steps.value = wholeLifeSteps.value
  }
  if (path.includes('advisor')) {
    // Couple of extra steps, go right to the form and set the hubspot_form value to advisor
    steps.value = advisorSteps.value
    goToStep('talk-to-an-advisor')
    store.form.hubspot_form = 'advisor'
  }
}

const goToSummaryFromAdvisor = () => {
  steps.value = termSteps.value
  goToStep('summary')
}

function reset(sendAnalytics = true) {
  stepStore.$reset()
  store.$reset()

  updateUrl('get-started')

  if (sendAnalytics) {
    window.dataLayer.push({
      event: 'webtool_reset_button_click',
      event_name: 'webtool_reset_button_click',
      event_category: 'webtool_navigation',
      event_label: '',
    })
  }
}

watch(
    () => currentStepKey.value,
    async () => {
      await nextTick()
      const step = document.getElementById(`step-${currentStepKey.value}`)
      if (step) {
        setTimeout(() => {
          const y = step.getBoundingClientRect().top + window.scrollY - 150
          window.scroll({
            top: y,
            behavior: "smooth"
          })
        }, 50)
      }
    }
)

// Need to ensure that the path for unsure updates if we get a product recommendation so just reassign the steps
watch(() => store.needsAssessment.productRecommendations, (newRecommendations, oldRecommendations) => {
  steps.value = notSureSteps.value
}, {deep: true})

watch(() => store.form.quote_product_selection, (newProduct, oldProduct) => {
  if (store.form.quote_product_selection) {
    if (newProduct.includes('WholeLife')) {
      store.form.hubspot_form = 'whole_life'
    }
    if (newProduct.includes('20')) {
      store.form.hubspot_form = '20_pay_whole_life'
    }
    if (newProduct.includes('Term')) {
      store.form.hubspot_form = null
    }
  }
}, {deep: true})

onMounted(() => {
  window.dataLayer.push({
    event_name: 'page_view',
    event_category: 'webtool_started',
    event_label: document.location.pathname + document.location.search,
  })

  const urlParams = new URLSearchParams(window.location.search)
  const stepParam = urlParams.get('step')
  if (stepParam && (stepParam === '20-pay' || stepParam === 'whole-life')) {
    steps.value = wholeLifeSteps.value
    stepStore.currentStepKey = 'request-quote'
  } else {
    // Check if the stepParam is in the stepMap and set the appropriate steps path
    if (stepMap[stepParam]) {
      steps.value = stepMap[stepParam]
    }

    // Ensure stepParam exists in the selected steps path and set it as the active step
    if (stepParam && steps.value.find(step => step.key === stepParam)) {
      stepStore.currentStepKey = stepParam
    } else {
      if (!stepStore.currentStepKey) {
        steps.value[0].key // Default to the first step if stepParam is invalid or we don't have one
      }
    }
  }


  const paramsToCheck = [
    'utm_campaign',
    'utm_source',
    'utm_medium',
    'utm_content',
    'fintel_click_id',
    'productid'
  ]

  paramsToCheck.forEach(param => {
    const value = urlParams.get(param)
    if (value) {
      store.form[param] = value
    }
  })

  store.form.uri = window.location.href

  goToStep(stepStore.currentStepKey)
})

function determineAnalyticsLabel() {
  const unsureSteps = {
    'get-started': 'getstarted',
    'product-needs': 'needs',
    'product-recommendations': 'productrecommendation',
    'do-the-math': 'calculator',
    'quote': 'quote',
    'summary': 'quotesummary',
  }

  const formLabels = {
    'whole_life': 'wholelife',
    '20_pay_whole_life': '20pay',
    'advisor': 'advisor',
  }

  return `webtool_${store.form.hubspot_form ? formLabels[store.form.hubspot_form] : unsureSteps[stepStore.currentStepKey]}`
}

window.addEventListener('beforeunload', e => {
  // e.preventDefault();
  const label = determineAnalyticsLabel()
  window.dataLayer.push({
    event: 'form_abandonment',
    event_category: 'webtool_navigation',
    event_label: label
  })
})

provide('steps', steps)
provide('goToNextStep', goToNextStep)
provide('goToStep', goToStep)
provide('goToPrevStep', goToPrevStep)
provide('changePath', changePath)
provide('updateUrl', updateUrl)
provide('currentStepIndex', currentStepIndex.value)
</script>

<template>
  <div class='app page-bursts style-3 g-cell-horizontal open--app tool'>
    <div class='app-body pos-relative transition'>
      <div class='g-container--wide'>
        <button class="s-button--plain" @click="reset()">Reset</button>
        <ToolNavigation :steps="steps" :count="0"/>
        <div v-for='step in steps' :key='step.key' class='step' :id="`step-${step.key}`" :class="{
          'is-active': step.key === currentStepKey,
        }">
          <div class='step-header' :class="{
              'is-complete': stepStore.completedSteps.includes(step.key),
          }" @click="goToStep(step.key)">
            <h2 class="text-h5">{{ step.header ? step.header : step.name }}</h2>
            <div class="icons">
              <CheckCircleIcon v-if="stepStore.completedSteps.includes(step.key)" class="h-icon"></CheckCircleIcon>
              <IconTime v-else/>
              <ChevronUpIcon v-if="step.key === currentStepKey" class="h-icon"></ChevronUpIcon>
              <ChevronDownIcon v-else class="h-icon"></ChevronDownIcon>
            </div>
          </div>
          <div class='step-content'>
            <component v-if='currentStepKey === step.key' :is='step.component' @complete='markStepComplete'
                       @change-path='changePath'>
              <template #navigation="{validate, pending, quoteViewed}">
                <div v-if="currentStepIndex > 0 && step.key === currentStepKey" class="step-navigation">
                  <template v-if="!store.hubspot.submitted">
                    <button v-if="currentStepKey === 'talk-to-an-advisor' && stepStore.completedSteps.includes('quote')"
                            class="s-button s-button--reverse" @click="goToSummaryFromAdvisor()">
                      Summary
                    </button>
                    <button v-else class="s-button s-button--reverse" @click="goToPrevStep()">
                      {{ steps[currentStepIndex - 1].name }}
                    </button>
                  </template>
                  <template v-if="currentStepKey === 'product-recommendations'">
                    <button
                        v-if="store.form.quote_product_selection && store.form.quote_product_selection.includes('100')"
                        @click="goToNextStep()"
                        class="s-button"
                    >
                      Quote
                    </button>
                    <button
                        v-else-if="store.form.quote_product_selection && (store.form.quote_product_selection.includes('WholeLife') || store.form.quote_product_selection.includes('20Pay'))"
                        @click="goToNextStep()"
                        class="s-button"
                    >
                      Request Quote
                    </button>
                    <button
                        v-else
                        @click="goToNextStep()"
                        class="s-button"
                    >
                      {{ steps[currentStepIndex + 1].name }}
                    </button>
                  </template>

                  <button
                      v-if="steps[currentStepIndex + 1] && currentStepKey !== 'quote' && currentStepKey !== 'product-recommendations'"
                      @click="goToNextStep()"
                      class="s-button"
                      :class="{
                    'disabled': currentStepKey === 'quote' && !stepStore.completedSteps.includes('quote')
                  }"
                  >
                    {{ steps[currentStepIndex + 1].name }}
                  </button>
                  <button
                      v-if="currentStepKey === 'quote'"
                      @click="validate"
                      :disabled="pending"
                      class="s-button"
                      :class="{'disabled': pending, 's-button--plain': pending}"
                  >
                    <ArrowPathIcon class="h-icon pending-icon" v-if="pending"/>
                    {{ steps[currentStepIndex + 1].name }}
                  </button>
                  <button
                      v-if="currentStepKey === 'summary' && !quoteViewed"
                      @click="validate"
                      class="s-button"
                  >
                    Email me this quote
                  </button>
                </div>
              </template>
            </component>
          </div>
        </div>
        <ToolNavigation :steps="steps" :count="1"/>
        <Disclaimers section="footer"/>
      </div>
    </div>
  </div>
  <ToolFooter/>
</template>


<style lang="scss">
.app {
  z-index: 10001;
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: auto;
  margin-bottom: 10vh;
}

.app-body {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.step {
  border-radius: 0.75rem;
  margin-bottom: 1rem;
  overflow: hidden;
  box-shadow: 0 4px 60px 0 rgba(0, 84, 166, .15);
  scroll-margin-top: 200px;
}

.step-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 0.75rem;
  cursor: pointer;
  border: 2px solid transparent;

  h2 {
    margin: 0;
  }

  .icons {
    display: flex;
    gap: 0.5rem;
  }

  &:hover {
    border: 2px solid var(--blue);
  }

  &.is-complete {
    background-color: #b1ec604a;
  }

  &.unavailable {
    cursor: not-allowed;

    &:hover {
      border: 2px solid transparent;
    }
  }
}

.step {
  &.is-active {
    box-shadow: none;

    .step-header {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      &:hover {
        border: 2px solid transparent;
      }
    }

    .step-content {
      padding: 1rem;
      background: white;
    }
  }
}

.step-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.tool {
  .s-button {
    &:focus {
      outline-offset: 2px;
      outline: var(--blue) solid 2px;
    }
  }
}

.pending-icon {
  margin-right: 0.5rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>