<script setup>
import { ref } from 'vue'

import promotion from '../../../images/promotion.png'
import promotionHover from '../../../images/promotion-hover.png'

const isHovered = ref(false)
</script>

<template>
  <img
      :src="isHovered ? promotionHover : promotion"
      @mouseover="isHovered = true"
      @mouseleave="isHovered = false"
      alt="Promotion Icon"
      class="h-icon-image"
  />
</template>

<style scoped lang="scss">

</style>
