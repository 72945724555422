export function useMoneyFormatter() {
    function formatMoney(value, decimals = 2, format = 'default') {
        if (format === 'number') {
            return (value === 0) ? "" : value.toLocaleString('en-US');
        } else {
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: decimals,
                maximumFractionDigits: decimals
            });
            if (value) {
                return formatter.format(value);
            }
            return formatter.format(0);
        }
    }

    return {formatMoney}
}
