<script setup>
import QuestionSlider from './QuestionSlider.vue'
import {computed, onMounted, ref, watch} from 'vue'
import {useEntryDataStore} from './stores/entryData'
import {useMoneyFormatter} from './composables/useMoneyFormatter'

const {formatMoney} = useMoneyFormatter()

const store = useEntryDataStore()

const emit = defineEmits(['update-answer', 'update-slider', 'request-quote'])

const optionModel = defineModel('optionModel')
// const sliderModel = defineModel('sliderModel')
const pending = defineModel('pending')

const props = defineProps({
  error: String,
  question: {
    label: {
      type: String,
    },
    description: {
      type: String,
    },
    question_type: {
      type: String,
    },
    question_key: {
      type: String,
    },
    option_list: {
      label: {
        type: String,
      },
      value: {
        type: String,
      },
      show_coverage_slider: {
        type: Boolean
      },
      coverage_slider: {
        label: {
          type: String,
        },
        min: {
          type: Number,
        },
        max: {
          type: Number,
        },
        question_key: {
          type: String,
        }
      }
    },
  },
  title: {
    type: String,
    required: false
  },
  description: {
    type: String,
    default: ''
  },
  options: {
    type: Array,
    default: () => []
  },
  layout: {
    type: String,
    default: 'column'
  },
  riders: {
    type: Array,
    default: () => []
  }
})

const selectedIndex = computed(() => {
  if (props.question.option_list) {
    return props.question.option_list.findIndex(option => option.value === optionModel.value)
  }
})

const coverageSlider = ref(null)

function setCoverageSlider() {
  if (selectedIndex.value !== -1 && props.question.option_list[selectedIndex.value].show_coverage_slider) {
    coverageSlider.value = props.question.option_list[selectedIndex.value].coverage_slider
  } else {
    coverageSlider.value = null
  }
}

watch(selectedIndex, () => {
  if (!riderQuestion.value) return
  setCoverageSlider()
})

function update(option) {
  if (coverageSlider.value) {
    if (option.value == 'false') {
      store.form[coverageSlider.value.question_key] = coverageSlider.value.value_slider.min
    }
  }
  optionModel.value = option.value
  emit('update-answer', option.value)
}

function updateSlider() {
  emit('update-slider', {question: coverageSlider.value, answer: store.form[coverageSlider.value.question_key]})
}

function isSelected(option) {
  if (!optionModel.value) {
    return false
  }
  return optionModel.value === option.value
}

const riderQuestion = ref(false)

// SMOKING SPECIFIC
const smokingQuestion = ref(false)
const smokingQuestionShown = ref(false)

function checkIfSmokingPossible() {
  if (store.form.calculated_age) {
    smokingQuestionShown.value = store.form.calculated_age >= 18
  }
}

watch(() => store.form.calculated_age, checkIfSmokingPossible, {deep: true})

// HEALTH MATCH SPECIFIC
const healthMatchQuestion = ref(false)
const healthMatchProduct = ref(false)
const coverageRequired = ref(false)
// controls visibility of last option in question options
const showLastOption = ref(true)

function checkIfHealthMatchPossible() {
  const requiredProducts = ['Term 10', 'Term 20', 'Term 30', 'Term to 100']
  healthMatchProduct.value = requiredProducts.includes(store.form.product_name)
  coverageRequired.value = store.form.coverage_amount_from_quote > 1000000
  if (store.form.smoking_class === 'N') {
    showLastOption.value = true
  } else {
    // Reset health match question if smoking is selected
    store.form.health_match = null
    store.quoteFields.health_match = null
    showLastOption.value = false
  }
}

watch([
      () => store.form.product_name,
      () => store.form.coverage_amount_from_quote,
      () => store.form.smoking_class
    ],
    () => {
      if (healthMatchQuestion.value) {
        checkIfHealthMatchPossible()
      }
    },
    {deep: true}
)

function optionLabel(option) {
  if (riderQuestion.value) {
    if (isSelected(option) && option.value == 'true') {
      return 'Added'
    }
  }
  return option.label ? option.label : option.post_title
}

onMounted(() => {
  if (props.question.question_key === 'health_match') {
    healthMatchQuestion.value = true
    checkIfHealthMatchPossible()
  }
  if (props.question.question_key === 'smoking_class') {
    smokingQuestion.value = true
    checkIfSmokingPossible()
  }
  if (props.riders.includes(props.question.question_key)) {
    riderQuestion.value = true
    setCoverageSlider()
  }
})
</script>

<template>
  <div>
    <div
        v-if="smokingQuestion && smokingQuestionShown || (healthMatchQuestion && healthMatchProduct && coverageRequired) || (!healthMatchQuestion && !smokingQuestion)"
        class="question">
      <template v-if="question.label">
        <h4 v-if="riderQuestion">
          {{ question.label }}
        </h4>
        <h3 v-else>
          {{ question.label }}
        </h3>
      </template>
      <div class="question-content" v-bind:class="(layout === 'row' ? 'row' : 'column')">
        <div v-if="question.description" v-html="question.description" class="question-description"></div>
        <div class="options-wrapper">
          <div :class="{'no-riders': !riderQuestion}">
            <div v-if="question.option_list.length" class="options" :class="{'error': props.error}">
              <template v-for="(option, index) in question.option_list" :key="index">
                <template v-if="healthMatchQuestion && index === question.option_list.length - 1">
                  <button v-if="showLastOption"
                          v-bind:class="(isSelected(option) ? 'active' : '')"
                          @click="update(option, index)"
                  >
                    {{ optionLabel(option) }}
                  </button>
                </template>
                <button
                    v-else
                    v-bind:class="(isSelected(option) ? 'active' : '')"
                    @click="update(option, index)"
                >
                  {{ optionLabel(option) }}
                </button>
              </template>
            </div>
            <span class="text-red" v-if="props.error">{{ props.error }}</span>
          </div>
          <div class="coverage-cost" v-if="store.form[`${question.question_key}`] && riderQuestion">
            <template v-if="pending">
              <div class="coverage-pending"></div>
            </template>
            <template v-else>
              <span class="amount">{{ formatMoney(store.form[`${question.question_key}_cost`]) }}</span>
              <span>added to your premium</span>
            </template>
          </div>
        </div>
        <div v-if="riderQuestion && coverageSlider" class="additional-slider">
          <QuestionSlider
              :question="coverageSlider"
              v-model="store.form[coverageSlider.question_key]"
              :riderQuestion="riderQuestion"
              @update-answer="updateSlider()"/>
        </div>
      </div>
      <slot/>

    </div>
  </div>
</template>

<style scoped lang="scss">
.question-title {
  font-size: 1.5rem;
  color: var(--blue);
  margin-bottom: 1rem;
}

.options-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;

  .text-red {
    display: block;
    margin: 1rem 0;
  }

  @media (min-width: 512px) {
    flex-direction: row;
  }

  @media (min-width: 921px) {
    align-items: center;
    justify-content: flex-start;
  }
}

.options {
  display: flex;
  align-items: center;
  width: 100%;

  @media (min-width: 768px) {
    justify-content: flex-start;
    width: auto;
  }

  button {
    width: 15rem;
    display: block;
    margin: 0.5rem;
    padding: 1rem;
    border: 1px solid rgba(30, 41, 59, 0.10);
    background: white;
    //flex: 1 1 0;
    border-radius: 0.75rem;
    cursor: pointer;
    transition: all .4s ease;
    text-align: center;
    color: #414042;

    &:hover,
    &:focus {
      border: 1px solid transparent;
      outline-offset: 2px;
      outline: var(--blue) solid 2px;
    }

    &.active {
      color: white;
      background: var(--blue);
    }
  }

  &.error {
    border-radius: 0.75rem;
    outline-offset: 2px;
    outline: #c87872 solid 2px;
  }
}

.question-content {
  display: flex;
  flex-direction: column;
  //align-items: flex-start;
  gap: 1rem;

  .options {
    flex-direction: column;

    @media (min-width: 921px) {
      flex-direction: row;
    }
  }

  .question-description {
    flex-basis: 50%;
  }

}

.no-riders {
  width: 100%;
}

.slider-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  //margin: 1rem 0;

  @media (min-width: 768px) {
    flex-direction: row;
    > div {
      flex: 1 1 50%;
    }
  }
}

.coverage-cost {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0.5rem;

  .amount {
    font-size: 1.5rem;
    color: var(--blue);
  }
}

.coverage-pending {
  width: 8rem;
  height: 2.5rem;
  background-color: #e0e0e0;
  border-radius: 4px;
  animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}
</style>
