// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-box {
  text-align: left;
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 0.75rem;
  font-size: 0.75rem;
  border: 1px solid rgba(30, 41, 59, 0.1);
}
.item-box:focus-within {
    outline-offset: 2px;
    outline: var(--blue) solid 2px;
}
.item-box input {
  font-size: 2rem;
  color: #0b73ba;
  border: none;
  padding: 0.75rem 0.5rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid cyan;
  width: 100%;
  box-sizing: border-box;
}
.item-box input.long-number {
    font-size: 1.75rem;
    line-height: 2.3rem;
}
.input-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}
.input-wrapper .prefix,
.input-wrapper .suffix {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2rem;
  color: #0b73ba;
  pointer-events: none;
  padding-bottom: 1rem;
}
.input-wrapper .prefix {
  left: 0.5rem;
}
.input-wrapper .suffix {
  right: 0.5rem;
}
input.with-prefix {
  padding-left: 2rem;
}
input.with-suffix {
  padding-right: 2rem;
}
.item-box input::placeholder {
  color: #0b73ba;
}
.item-box input:disabled {
  background-color: white;
}
`, "",{"version":3,"sources":["webpack://./../assets/vue/DoTheMathFormInput.vue"],"names":[],"mappings":"AACA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,uCAAwC;AAAA;AAN1C;IASI,mBAAmB;IACnB,8BAA8B;AAAA;AAIlC;EACE,eAAe;EACf,cAAc;EACd,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;EACnB,6BAA6B;EAC7B,WAAW;EACX,sBAAsB;AAAA;AARxB;IAWI,kBAAkB;IAClB,mBAAmB;AAAA;AAIvB;EACE,kBAAkB;EAClB,qBAAqB;EACrB,WAAW;AAAA;AAGb;;EAEE,kBAAkB;EAClB,QAAQ;EACR,2BAA2B;EAC3B,eAAe;EACf,cAAc;EACd,oBAAoB;EACpB,oBAAoB;AAAA;AAGtB;EACE,YAAY;AAAA;AAGd;EACE,aAAa;AAAA;AAGf;EACE,kBAAkB;AAAA;AAGpB;EACE,mBAAmB;AAAA;AAGrB;EACE,cAAc;AAAA;AAGhB;EACE,uBAAuB;AAAA","sourcesContent":["\n.item-box {\n  text-align: left;\n  background-color: #ffffff;\n  padding: 1rem;\n  border-radius: 0.75rem;\n  font-size: 0.75rem;\n  border: 1px solid rgba(30, 41, 59, 0.10);\n\n  &:focus-within {\n    outline-offset: 2px;\n    outline: var(--blue) solid 2px;\n  }\n}\n\n.item-box input {\n  font-size: 2rem;\n  color: #0b73ba;\n  border: none;\n  padding: 0.75rem 0.5rem;\n  margin-bottom: 1rem;\n  border-bottom: 1px solid cyan;\n  width: 100%;\n  box-sizing: border-box;\n\n  &.long-number {\n    font-size: 1.75rem;\n    line-height: 2.3rem;\n  }\n}\n\n.input-wrapper {\n  position: relative;\n  display: inline-block;\n  width: 100%;\n}\n\n.input-wrapper .prefix,\n.input-wrapper .suffix {\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n  font-size: 2rem;\n  color: #0b73ba;\n  pointer-events: none;\n  padding-bottom: 1rem;\n}\n\n.input-wrapper .prefix {\n  left: 0.5rem;\n}\n\n.input-wrapper .suffix {\n  right: 0.5rem;\n}\n\ninput.with-prefix {\n  padding-left: 2rem;\n}\n\ninput.with-suffix {\n  padding-right: 2rem;\n}\n\n.item-box input::placeholder {\n  color: #0b73ba;\n}\n\n.item-box input:disabled {\n  background-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
