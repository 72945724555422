<script setup>
import QuoteEmailForm from './QuoteEmailForm.vue'
import {onMounted, ref} from 'vue'
import Disclaimers from './Disclaimers.vue'
import {useEntryDataStore} from './stores/entryData'

const data = QUOTE.summary

const store = useEntryDataStore()

const showQuoteButton = ref(false)

onMounted(() => {
  window.dataLayer.push({
    event: 'page_view',
    event_name: 'page_view',
    event_category: 'webtool_advisorcontact',
    event_label: '',
  })

  if (store.quote.complete && store.form.quote_product_selection.includes('Term')) {
    showQuoteButton.value = true
  }
})
</script>

<template>
  <div>
    <QuoteEmailForm :data="data.advisor" :submit-label="`Book a Meeting`"/>
    <Disclaimers section="talk_to_an_advisor"/>
    <slot name="navigation"/>
  </div>
</template>

<style>
</style>