<template>
  <div class="item-box">
    <label>{{ amount.label }}</label>
    <div class="input-wrapper">
      <input
          type="text"
          :name="name"
          :placeholder="defaultValue"
          :class="{
            'with-suffix': amount.type === 'percentage',
            'long-number': value > 10000000
          }"
          :value="amount.type === 'dollar' ? formatMoney(value, 0) : value"
          @click="handleClick"
          @input="handleInput"
      />
      <span v-if="amount.type === 'percentage'" class="suffix">%</span>
    </div>
    <div class="small" v-html="amount.description"></div>
  </div>
</template>

<script setup>
import {computed} from 'vue'
import {useEntryDataStore} from './stores/entryData'
import {useMoneyFormatter} from './composables/useMoneyFormatter'

const props = defineProps({
  amount: {
    type: Object
  },
  value: {
    type: Number
  },
  name: {
    type: String
  }
})
const emit = defineEmits(['update'])

const {formatMoney} = useMoneyFormatter()
const store = useEntryDataStore()

const analytics = {
  category: 'webtool_calculator'
}

const defaultValue = computed(() => {
  return props.amount.type === 'dollar' || props.amount.type === 'percentage' ? 0 : 0
})

const handleInput = (event) => {
  let value = event.target.value.replace(/\D/g, '')

  let numericValue = value === '' ? 0 : Number(value)

  if (props.amount.limit && numericValue > Number(props.amount.limit)) {
    numericValue = Number(props.amount.limit)
  }

  emit('update', numericValue)
  if (props.amount.type === 'dollar') {
    event.target.value = formatMoney(numericValue, 0)
  } else {
    event.target.value = numericValue
  }
}

const handleClick = () => {
  if (!store.doTheMath.initialEvent) {
    window.dataLayer.push({
      event: 'accordion_started',
      event_name: 'accordion_started',
      event_category: analytics.category,
      event_label: ''
    })
    store.doTheMath.initialEvent = true
  }
  window.dataLayer.push({
    event: 'accordion_engagement',
    event_name: 'accordion_engagement',
    event_category: analytics.category,
    event_label: props.amount.label
  })
}
</script>

<style lang="scss">
.item-box {
  text-align: left;
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 0.75rem;
  font-size: 0.75rem;
  border: 1px solid rgba(30, 41, 59, 0.10);

  &:focus-within {
    outline-offset: 2px;
    outline: var(--blue) solid 2px;
  }
}

.item-box input {
  font-size: 2rem;
  color: #0b73ba;
  border: none;
  padding: 0.75rem 0.5rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid cyan;
  width: 100%;
  box-sizing: border-box;

  &.long-number {
    font-size: 1.75rem;
    line-height: 2.3rem;
  }
}

.input-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.input-wrapper .prefix,
.input-wrapper .suffix {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2rem;
  color: #0b73ba;
  pointer-events: none;
  padding-bottom: 1rem;
}

.input-wrapper .prefix {
  left: 0.5rem;
}

.input-wrapper .suffix {
  right: 0.5rem;
}

input.with-prefix {
  padding-left: 2rem;
}

input.with-suffix {
  padding-right: 2rem;
}

.item-box input::placeholder {
  color: #0b73ba;
}

.item-box input:disabled {
  background-color: white;
}
</style>
