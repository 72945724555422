import {computed} from 'vue'
import {useEntryDataStore} from '../stores/entryData'

export function useAnalyticsCategory(defaultCategory) {
    const store = useEntryDataStore()

    return computed(() => {
        if (store.form.hubspot_form === 'advisor') {
            return 'webtool_advisorcontact'
        }
        if (!store.form.hubspot_form) {
            return 'webtool_fulljourney'
        }
        if (store.form.quote_product_selection) {
            if (store.form.quote_product_selection.includes('WholeLife')) {
                return 'webtool_wholelife'
            }
            if (store.form.quote_product_selection.includes('20')) {
                return 'webtool_20pay'
            }
        }
        return defaultCategory
    })
}