<script setup>
import {computed, onMounted, ref, watch} from 'vue'
import {useMoneyFormatter} from './composables/useMoneyFormatter'
import Slider from '@vueform/slider'
import {useEntryDataStore} from './stores/entryData'

const store = useEntryDataStore()

const props = defineProps({
  question: {
    label: {
      type: String,
    },
    question_type: {
      type: String,
    },
    question_key: {
      type: String,
    },
    value_slider: {
      min: {
        type: Number,
      },
      max: {
        type: Number,
      },
      default: {
        type: Number,
      }
    }
  },
  modelValue: Number,
  riderQuestion: Boolean,
})

const model = defineModel()
const formattedModel = ref('')
const min = ref(50000)
const max = ref(5000000)

const initialDefaultSet = ref(false)

const emit = defineEmits(['update-answer'])

const {formatMoney} = useMoneyFormatter()

const handleClick = () => {
  // window.dataLayer.push({
  //   event: 'accordion_engagement',
  //   event_name: 'accordion_engagement',
  //   event_category: 'webtool_quote',
  //   event_label: props.question.label
  // })
}

function setRangeValues() {
  const terms = ['Term 10', 'Term 20', 'Term 30', 'Term to 100']
  if (terms.includes(store.form.product_name)) {
    if (store.form.product_name === 'Term to 100') {
      max.value = 2000000
      if (store.form.calculated_age >= 65) {
        min.value = 10000
      } else if (store.form.calculated_age >= 30 && store.form.calculated_age < 65) {
        min.value = 25000
      } else {
        min.value = 50000
      }
    } else {
      min.value = 50000
      max.value = 5000000
    }
  } else {
    min.value = Number(props.question.value_slider.min)
    max.value = Number(props.question.value_slider.max)
  }
  if (props.riderQuestion) {
    min.value = Number(props.question.value_slider.min)
    max.value = Number(props.question.value_slider.max)
    if (max.value > store.form.coverage_amount_from_quote) {
      max.value = Number(store.form.coverage_amount_from_quote)
    } else {
      max.value = Number(props.question.value_slider.max)
    }
  } else {
    // if (store.form.coverage_amount_from_quote < min.value) {
    //   store.form.coverage_amount_from_quote = min.value
    //   console.log('min')
    // }
  }
}

watch([
  () => store.form.product_name,
  () => store.form.calculated_age,
  () => store.form.coverage_amount_from_quote
], () => {
  setRangeValues()
})

watch(formattedModel, (newVal) => {
  const rawValue = newVal.replace(/[\D\s\._\-]+/g, '')
  if (parseInt(rawValue, 10) > 0) {
    model.value = parseInt(rawValue, 10)
  }
  // Have to use watch here, onMounted doesn't like to set the value. This should only trigger once if no value is set
  // if (!initialDefaultSet.value && model.value === 0) {
  //   model.value = Number(props.question.value_slider.default)
  //   formattedModel.value = Number(props.question.value_slider.default).toLocaleString('en-US')
  //   initialDefaultSet.value = true
  // }
})

const handleInput = (event) => {
  let input = event.target.value.replace(/[\D\s\._\-]+/g, '')
  if (input) {
    input = parseInt(input, 10).toLocaleString('en-US')
  }
  formattedModel.value = input
  // emit('update-answer', model.value)
}

const handleBlur = () => {
  let rawValue = formattedModel.value.replace(/[\D\s\._\-]+/g, '')
  rawValue = parseInt(rawValue, 10)

  if (!rawValue) {
    rawValue = min.value
  } else {
    // Round value to nearest K here
    const roundedKeys = ['gio_amount', 'ctb_amount']
    if (roundedKeys.includes(props.question.question_key)) {
      // Determine rounding up or down
      const remainder = rawValue % 1000

      if (remainder <= 500) {
        // Down
        rawValue = Math.floor(rawValue / 1000) * 1000
      } else {
        // Up
        rawValue = Math.ceil(rawValue / 1000) * 1000
      }
    }
    if (rawValue < min.value) {
      rawValue = min.value
    } else if (rawValue > max.value) {
      rawValue = max.value
    }
  }

  formattedModel.value = rawValue.toLocaleString('en-US')
  model.value = rawValue
  emit('update-answer', model.value)
}

onMounted(() => {
  if (!props.riderQuestion) {
    if (model.value === 0) {
      emit('update-answer', Number(props.question.value_slider.default))
      formattedModel.value = parseInt(Number(props.question.value_slider.default), 10).toLocaleString('en-US')
    } else {
      formattedModel.value = parseInt(model.value, 10).toLocaleString('en-US')
    }
  } else {
    formattedModel.value = parseInt(model.value, 10).toLocaleString('en-US')
  }
  setRangeValues()
})
</script>

<template>
  <div class="question">
    <template v-if="question.label">
      <h5 v-if="riderQuestion">
        {{ question.label }}
      </h5>
      <h3 v-else>
        {{ question.label }}
      </h3>
    </template>
    <div v-if="question.description" v-html="question.description" class="question-description"></div>
    <div class="input-range">
      <div>
        <label :for="`question-${question.question_key}`" class="small">Enter Coverage Amount</label>
        <div class="input-wrapper">
          <span class="prefix">$</span>
          <input
              :id="`question-${question.question_key}`"
              type="text"
              v-model="formattedModel"
              @input="handleInput"
              @click="handleClick"
              @blur="handleBlur"
          />
        </div>
      </div>
      <div>
        <div class="range-values">
          <span>{{ formatMoney(min, 0) }}</span><span>{{ formatMoney(max, 0) }}</span>
        </div>
        <Slider
            v-model="model"
            :min="min"
            :max="max"
            class="slider-override"
            :tooltips="false"
            disabled
            :format="{prefix: '$', decimals: 0, thousand: ','}"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.question-title {
  font-size: 1.5rem;
  color: var(--blue);
  margin-bottom: 1rem;
}

.slider-override {
  --slider-connect-bg: var(--blue);
  --slider-tooltip-bg: var(--blue);
  margin-bottom: 2rem;
}

.input-range {
  background: #ffffff;
  border-radius: 0.75rem;
  padding: 1rem;
  margin: 1rem 0;
  border: 1px solid rgba(30, 41, 59, 0.10);
  display: flex;
  flex-direction: column;
  gap: 2rem;

  &:focus-within {
    outline-offset: 2px;
    outline: var(--blue) solid 2px;
  }

  @media (min-width: 921px) {
    max-width: 50%;
  }

  label {
    font-size: 0.75rem;
  }

  .input-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;

    input {
      font-size: 1.25rem;
      color: #0b73ba;
      border: none;
      margin-bottom: 0.5rem;
      border-bottom: 1px solid cyan;
      width: 100%;
      box-sizing: border-box;
      padding: 0.75rem 0.5rem 0.75rem 0.75rem;
    }

    .prefix {
      //  position: absolute;
      //  top: 50%;
      //  transform: translateY(-50%);
      font-size: 1.25rem;
      //  color: #0b73ba;
      //  pointer-events: none;
      padding-bottom: 0.5rem;
      left: 0;
    }
  }
}

.range-values {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.additional-slider h3 {
  font-size: 1.5rem;
}

//.additional-slider .input-range {
//  margin: 1rem 0;
//  max-width: 100%;
//}
</style>