<script setup>
import {useEntryDataStore} from './stores/entryData'
import {computed, onMounted, ref, watch} from 'vue'
import {DocumentCheckIcon, QuestionMarkCircleIcon, XMarkIcon} from '@heroicons/vue/24/outline'
import Dialog from './components/Dialog.vue'

const data = QUOTE.content

const store = useEntryDataStore()

const emit = defineEmits(['update-answer', 'complete'])

const model = defineModel()

const props = defineProps({
  error: String,
  question: {
    label: {
      type: String,
    },
    description: {
      type: String,
    },
    question_type: {
      type: String,
    },
    question_key: {
      type: String,
    },
    product_select: {
      term_products: {
        label: {
          type: String,
        },
        description: {
          type: String
        },
        options: {
          type: Object
        }
      },
      whole_products: {
        label: {
          type: String,
        },
        description: {
          type: String
        },
        options: {
          type: Object
        }
      }
    },
  },
  layout: {
    type: String,
    default: 'column'
  }
})

const questionKeys = ['term_products', 'whole_products']

function update(product) {
  store.form.product_name = product.post_title
  model.value = product.quote_product_value

  const products = ['Whole Life', '20-Pay Whole Life']
  if (products.includes(store.form.product_name)) {
    wholeLifeDialog.value.open = true
  }

  emit('update-answer', product.quote_product_value)
}

function isSelected(product) {
  if (!model.value) {
    return false
  }
  // get values for hubspot configured on products
  const hubspotValues = [product.get_started_product_value, product.quote_product_value]
  return hubspotValues.includes(model.value)
}

const termProducts = ref(null)
const wholeProducts = ref(null)

const wholeLifeDialog = ref({
  open: false,
  icon: DocumentCheckIcon,
  title: data.whole_life_dialog_title,
  description: data.whole_life_dialog_content
})

const productDialogs = ref({})

function initializeProductsAndDialogs(age) {
  let _termProducts = props.question.product_select['term_products']
  let _wholeProducts = props.question.product_select['whole_products']

  if (!age) {
    return
  }
  if (!_termProducts) {
    termProducts.value = {}
    return
  }
  if (!_wholeProducts) {
    wholeProducts.value = {}
    return
  }

  const termAgeRanges = {
    'Term 10': [18, 75],
    'Term 20': [18, 65],
    'Term 30': [18, 55],
    'Term to 100': [30, 80]
  }

  let termOptions = _termProducts.options.filter(option => {
    const range = termAgeRanges[option.post_title]
    return range && age >= range[0] && age <= range[1]
  })

  termProducts.value = {
    ..._termProducts,
    options: termOptions
  }

  const wholeAgeRanges = {
    'Whole Life': [0, 80],
    '20-Pay Whole Life': [0, 80],
    'Term to 100': [30, 80]
  }

  let wholeOptions = _wholeProducts.options.filter(option => {
    const range = wholeAgeRanges[option.post_title]
    return range && age >= range[0] && age <= range[1]
  })

  wholeProducts.value = {
    ..._wholeProducts,
    options: wholeOptions
  }

  termProducts.value.options.forEach((productOption) => {
    productDialogs.value[productOption.post_title] = {
      open: false,
      icon: QuestionMarkCircleIcon,
      title: productOption.post_title,
      description: productOption.tooltip_description
    }
  })

  wholeProducts.value.options.forEach((productOption) => {
    productDialogs.value[productOption.post_title] = {
      open: false,
      icon: QuestionMarkCircleIcon,
      title: productOption.post_title,
      description: productOption.tooltip_description
    }
  })
}

function toggleDialog(title) {
  if (productDialogs.value[title]) {
    productDialogs.value[title].open = !productDialogs.value[title].open
  }
}

watch(() => store.form.calculated_age, (age) => {
  initializeProductsAndDialogs(age)
}, {deep: true})

const products = computed(() => {
  let products = {}
  if (termProducts.value?.options && termProducts.value?.options.length) {
    products['term_products'] = termProducts.value
  }

  if (wholeProducts.value?.options && wholeProducts.value?.options.length) {
    products['whole_products'] = wholeProducts.value
  }
  return products
})

onMounted(() => {
  initializeProductsAndDialogs(store.form.calculated_age)
})
</script>

<template>
  <div>
    <div v-for="(questionKey, index) in questionKeys" class="question" :key="index">
      <template v-if="products[questionKey]">
        <h4 v-if="products[questionKey].label">
          {{ products[questionKey].label }}
        </h4>
        <div class="question-content" v-bind:class="(layout === 'row' ? 'row' : 'column')">
          <div v-if="products[questionKey].description" v-html="products[questionKey].description"
               class="question-description"></div>
          <div class="options-wrapper">
            <div v-if="products[questionKey].options" class="options" :class="{'error': props.error}">
              <div class="option" v-for="(option, optionIndex) in products[questionKey].options"
                   :key="optionIndex">
                <button class="dialog-button"
                        v-bind:class="(isSelected(option) ? 'active' : '')"
                        @click="update(option)"
                >
                  {{ option.post_title }}
                </button>
                <div class="product-dialog-button-wrapper">
                  <button
                      class="product-dialog-button"
                      v-if="option.tooltip_description"
                      @click="toggleDialog(option.post_title)"
                      :aria-label="`More information about ${option.post_title}`">
                    <QuestionMarkCircleIcon class="h-icon"/>
                  </button>
                </div>
              </div>
            </div>
            <span class="text-red" v-if="props.error">{{ props.error }}</span>
          </div>
        </div>
      </template>
    </div>
    <Dialog
        :open="wholeLifeDialog.open"
        class="dialog"
        role="dialog"
        aria-labelledby="dialog-title-whole-life"
        :aria-describedby="wholeLifeDialog.description ? 'dialog-desc-whole-life' : null"
    >
      <div class="dialog-content">
        <div class="dialog-title-wrap">
          <div class="dialog-title" id="dialog-title-whole-life">
            <component :is="wholeLifeDialog.icon" class="h-icon"/>
            <span>{{ wholeLifeDialog.title }}</span>
          </div>
          <button @click="wholeLifeDialog.open = false">
            <XMarkIcon class="h-icon"/>
          </button>
        </div>
        <p v-if="wholeLifeDialog.description" id="dialog-desc-whole-life" v-html="wholeLifeDialog.description"></p>
        <button class="s-button--plain dialog-close" @click="emit('complete')">Request a Quote</button>
      </div>
    </Dialog>
    <Dialog v-for="(dialog, index) in productDialogs"
            :key="index"
            :open="dialog.open"
            class="dialog"
            role="dialog"
            :id="'dialog-' + index"
            :aria-labelledby="'dialog-title-' + index"
            :aria-describedby="dialog.description ? 'dialog-desc-' + index : null"
            @keydown.esc="dialog.open = false">
      <div class="dialog-content" tabindex="-1">
        <div class="dialog-title-wrap">
          <div class="dialog-title" :id="'dialog-title-' + index">
            <component v-if="dialog.icon" :is="dialog.icon" class="h-icon"/>
            <span v-html="dialog.title"></span>
          </div>
          <button @click="dialog.open = false" aria-label="Close dialog">
            <XMarkIcon class="h-icon"/>
          </button>
        </div>
        <p v-if="dialog.description" :id="'dialog-desc-' + index" v-html="dialog.description"></p>
        <button class="s-button--plain dialog-close" @click="dialog.open = false">Close Window</button>
      </div>
    </Dialog>
  </div>
</template>

<style scoped lang="scss">
.question {
  margin-bottom: 2rem;
}

.question-label {
  font-size: 1.5rem;
  color: var(--blue);
  margin-bottom: 1rem;
}

.options-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.options {
  display: flex;
  align-items: center;
  width: 100%;

  @media (min-width: 768px) {
    justify-content: flex-start;
    width: auto;
  }

  .option {
    display: flex;
    align-items: center;
    gap: 0.25rem;

    .tooltip-wrapper {
      width: 24px;
    }
  }

  button {
    width: 15rem;
    display: block;
    margin: 0.5rem;
    padding: 1rem;
    border: 1px solid rgba(30, 41, 59, 0.10);
    background: white;
    flex: 1 1 0;
    border-radius: 0.75rem;
    cursor: pointer;
    transition: all .4s ease;
    text-align: center;
    color: var(--blue);

    &:hover,
    &:focus {
      border: 1px solid transparent;
      outline-offset: 2px;
      outline: var(--blue) solid 2px;
    }

    &.active {
      color: white;
      background: var(--blue);
    }
  }

  .product-dialog-button-wrapper {
    width: 24px;
  }

  .product-dialog-button {
    width: auto;
    padding: 0;
    border: none;
    background: none;
    color: black;
    text-align: initial;
    flex-grow: 0;

    &:hover,
    &:focus {
      border: none;
      outline: none;
    }
  }

  &.error {
    border-radius: 0.75rem;
    outline-offset: 2px;
    outline: #c87872 solid 2px;

    .text-red {
      margin: 0.5rem;
    }
  }
}

.question-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &.row {
    align-items: center;

    .options {
      flex-direction: column;

      @media (min-width: 768px) {
        flex-direction: row;
      }
    }

  }

  &.column {
    .options {
      flex-direction: column;
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;
  }

  .question-description {
    flex-basis: 50%;
  }

}

.dialog {
  border: none;
  border-radius: 0.75rem;
  width: 75vw;

  .dialog-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .dialog-title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dialog-title {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      font-size: 1.25rem;
    }

    button {
      border: none;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2rem;
      width: 2rem;
      cursor: pointer;
    }
  }

  .dialog-close {
    justify-content: center;
  }
}
</style>
