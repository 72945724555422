// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.math-container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.recommended-coverage {
  border-radius: 0.75rem;
  border: 2px solid var(--blue);
  padding: 1rem;
}
.recommended-coverage .total-amount {
    background-color: #ffffff;
    border-radius: 0.75rem;
    width: initial;
    margin-bottom: 1rem;
}
.recommended-coverage .total-amount span {
      color: #0b73ba;
}
.recommended-coverage .total-amount .amount {
      font-size: 1.5rem;
      margin-bottom: 1rem;
}
.recommended-coverage .calculation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
.recommended-coverage .calculation .item-box {
      width: 100%;
}
.recommended-coverage .calculation input {
      font-size: 1.5rem;
}
@media (min-width: 1150px) {
.recommended-coverage .calculation {
        flex-direction: row;
        align-items: center;
        gap: 0.25rem;
}
}
.recommended-coverage .calculation .math-symbol {
      font-size: 1.5rem;
}
`, "",{"version":3,"sources":["webpack://./../assets/vue/DoTheMathQuiz.vue"],"names":[],"mappings":"AACA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AAAA;AAGX;EACE,sBAAsB;EACtB,6BAA6B;EAC7B,aAAa;AAAA;AAHf;IAMI,yBAAyB;IACzB,sBAAsB;IACtB,cAAc;IACd,mBAAmB;AAAA;AATvB;MAYM,cAAc;AAAA;AAZpB;MAgBM,iBAAiB;MACjB,mBAAmB;AAAA;AAjBzB;IAsBI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;AAAA;AA1Bb;MA6BM,WAAW;AAAA;AA7BjB;MAiCM,iBAAiB;AAAA;AAGnB;AApCJ;QAqCM,mBAAmB;QACnB,mBAAmB;QACnB,YAAY;AAAA;AAOf;AA9CH;MA2CM,iBAAiB;AAAA","sourcesContent":["\n.math-container {\n  display: flex;\n  flex-direction: column;\n  gap: 3rem;\n}\n\n.recommended-coverage {\n  border-radius: 0.75rem;\n  border: 2px solid var(--blue);\n  padding: 1rem;\n\n  .total-amount {\n    background-color: #ffffff;\n    border-radius: 0.75rem;\n    width: initial;\n    margin-bottom: 1rem;\n\n    span {\n      color: #0b73ba;\n    }\n\n    .amount {\n      font-size: 1.5rem;\n      margin-bottom: 1rem;\n    }\n  }\n\n  .calculation {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    gap: 1rem;\n\n    .item-box {\n      width: 100%;\n    }\n\n    input {\n      font-size: 1.5rem;\n    }\n\n    @media (min-width: 1150px) {\n      flex-direction: row;\n      align-items: center;\n      gap: 0.25rem;\n    }\n\n    .math-symbol {\n      font-size: 1.5rem;\n    }\n\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
