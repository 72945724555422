<template>
  <div>
    <h3 class="question-title">{{ step.heading }}</h3>
    <div class="question-text" v-html="(step) ? step.question : null"></div>
    <div class="v-quiz__inputs">
      <do-the-math-form-input
          v-for="(amount, index) in step.amounts_list"
          :key="index"
          :amount="amount"
          :value="store.doTheMath[step.question_key][amount.label] ? Number(store.doTheMath[step.question_key][amount.label]) : 0"
          :name="amount.label.toLowerCase().replace(/[, ]+/g, '_')"
          @update="update(amount.label, $event)"
      />
    </div>
    <div class="total-amount">
      {{ step.total_label }} <span>{{ formattedTotal }}</span>
    </div>
  </div>
</template>

<script setup>
import {computed} from 'vue'
import DoTheMathFormInput from './DoTheMathFormInput.vue'
import {useEntryDataStore} from './stores/entryData'
import {useMoneyFormatter} from "./composables/useMoneyFormatter";

const {formatMoney} = useMoneyFormatter()


const props = defineProps({
  step: Object
})

const emit = defineEmits(['update'])

const store = useEntryDataStore()

const update = (label, value) => {
  if (!store.doTheMath[props.step.question_key]) {
    store.doTheMath[props.step.question_key] = {}
  }

  // Update the specific label within the question_key
  store.doTheMath[props.step.question_key][label] = value
  if (props.step.question_key === 'income_needs') {
    store.doTheMath[`${props.step.question_key}_total`] = incomeShortage.value
    store.form.total_needed_income_shortage = incomeShortage.value
  } else {
    store.doTheMath[`${props.step.question_key}_total`] = totalAmount.value
    store.form[`total_${props.step.question_key}`] = totalAmount.value
  }

  emit('update')
}

const totalAmount = computed(() => {
  const entries = store.doTheMath[props.step.question_key] || {}
  return Object.values(entries).reduce((total, value) => total + (parseFloat(value) || 0), 0)
});

const incomeShortage = computed(() => {
  if (!props.step.question_key === 'income_needs') return
  const entries = store.doTheMath[props.step.question_key] || {}
  const entryValues = Object.values(entries)

  const householdIncome = entryValues[0] ?? 0
  const incomePercentage = entryValues[1] ?? 0
  const years = entryValues[2] ?? 0
  const inflation = 2 // do something about these constants
  const rateOfReturn = 5

  const annualHouseholdIncomeNeed = (householdIncome * incomePercentage) / 100
  const effectiveInflation = Math.min(inflation, rateOfReturn)
  // Calculate income shortage over time
  let incomeShortage = 0
  if (rateOfReturn !== effectiveInflation) {
    incomeShortage =
        annualHouseholdIncomeNeed *
        ((1 - Math.pow((1 + effectiveInflation / 100) / (1 + rateOfReturn / 100), years)) /
            ((rateOfReturn - effectiveInflation) / 100)) *
        (1 + rateOfReturn / 100)
  } else {
    incomeShortage = annualHouseholdIncomeNeed * years
  }

  // Ensure the result is finite
  if (!isFinite(incomeShortage)) {
    incomeShortage = 0
  }

  return Math.round(incomeShortage)
})

// Format total as a dollar value
const formattedTotal = computed(() => {
  if (props.step.question_key === 'income_needs') {
    return formatMoney(incomeShortage.value, 0)
  }
  return formatMoney(totalAmount.value, 0)
})

</script>

<style scoped lang="scss">
.question-title {
  text-align: center;
}

.v-quiz__inputs {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  margin: 1rem 0;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1150px) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.total-amount {
  padding: 1rem;
  border-radius: 0.75rem;
  color: white;
  background-color: var(--blue);
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;

  span {
    color: white;
  }
}
</style>
