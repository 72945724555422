<script setup>
import { ref } from 'vue'

import benefits from '../../../images/benefits.png'
import benefitsHover from '../../../images/benefits-hover.png'

const isHovered = ref(false)
</script>

<template>
  <img
      :src="isHovered ? benefitsHover : benefits"
      @mouseover="isHovered = true"
      @mouseleave="isHovered = false"
      alt="Benefits Icon"
      class="h-icon-image"
  />
</template>

<style scoped lang="scss">

</style>