<script setup>
import Question from './QuoteOptionQuestion.vue'
import QuestionSlider from './QuestionSlider.vue'
import {useEntryDataStore} from './stores/entryData'
import {computed, nextTick, onMounted, ref, watch} from 'vue'
import QuoteProductQuestion from './QuoteProductQuestion.vue'
import axios from 'axios'
import qs from 'qs'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import Disclaimers from './Disclaimers.vue'
import {useStepStore} from './stores/steps'

const data = QUOTE.data
const content = QUOTE.content

const store = useEntryDataStore()
const stepStore = useStepStore()

const emit = defineEmits(['complete'])

const analytics = {
  category: 'webtool_quote'
}

const submitted = ref(false)
const errorMessage = ref(null)
const error = ref(false)
const quote = ref(null)
const quoteKey = ref(0)
const errors = ref({})
const pending = ref(false)

const submit = async (triggerSummaryPage = false) => {
  let response = null
  try {
    pending.value = true
    errorMessage.value = null
    error.value = false
    const data = {...store.form, action: 'quote_submission'}
    response = await axios.post(GET_STARTED.ajax_url, qs.stringify(data))
    if (response.data.success) {
      submitted.value = true
      quote.value = response.data.data.quote
      store.form.annual_premium_cost_quote = quote.value['firstYearPremiumAnnual']
      if (store.form.payment_mode === "03") {
        store.form.quarterly_premium_cost = quote.value['firstYearModalPremium']
        store.form.monthly_premium_cost = 0
      } else if (store.form.payment_mode === "01") {
        store.form.monthly_premium_cost = quote.value['firstYearModalPremium']
        store.form.quarterly_premium_cost = 0
      } else {
        store.form.quarterly_premium_cost = 0
        store.form.monthly_premium_cost = 0
      }

      if (quote.value['coverageListItems']['Base']) {
        let basic_cost = quote.value['coverageListItems']['Base']['modalPremium']

        if (store.form.product_name && quote.value['coverageListItems'][store.form.product_name]) {
          basic_cost += quote.value['coverageListItems'][store.form.product_name]['modalPremium']
        }
        store.form.basic_plan_cost_quote = basic_cost
      }

      if (quote.value['coverageListItems']['Child Protector']) {
        store.form.child_term_benefit_cost = quote.value['coverageListItems']['Child Protector']['modalPremium']
        store.form.ctb_cost = quote.value['coverageListItems']['Child Protector']['modalPremium']
      }

      if (quote.value['coverageListItems']['DPW']) {
        store.form.total_disability_waiver_cost = quote.value['coverageListItems']['DPW']['modalPremium']
        store.form.tdw_cost = quote.value['coverageListItems']['DPW']['modalPremium']
      }

      if (quote.value['coverageListItems']['GPO']) {
        store.form.guaranteed_insurability_option_cost = quote.value['coverageListItems']['GPO']['modalPremium']
        store.form.gio_cost = quote.value['coverageListItems']['GPO']['modalPremium']
      }

      if (quote.value['coverageListItems']['ADB']) {
        store.form.accidental_death_benefit_cost = quote.value['coverageListItems']['ADB']['modalPremium']
        store.form.adb_cost = quote.value['coverageListItems']['ADB']['modalPremium']
      }

      if (quote.value['calculatedESA']) {
        store.form.age = quote.value['calculatedESA']
      }
    }
    quoteKey.value++
    if (triggerSummaryPage) {
      validateForm()
    }
  } catch (err) {
    errorMessage.value = 'There was an error getting your premium, please try again later or contact our member services.'
    error.value = true
  }
  pending.value = false
}

function calculateAge(date) {
  const dob = new Date(date);
  const today = new Date();

  let age = today.getFullYear() - dob.getFullYear();
  let monthDiff = today.getMonth() - dob.getMonth();
  let dayDiff = today.getDate() - dob.getDate();

  // If the birthday hasn't occurred yet this year, subtract 1 from age
  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    age--;
    // Correct month difference for earlier month in the year
    monthDiff += 12;
  }

  // Round the age up only if it's more than 6 months past the birthday
  if (monthDiff > 6 || (monthDiff === 6 && dayDiff > 0)) {
    age++;
  }

  return age;
}

function update(question, answer) {
  const key = question.question_key
  window.dataLayer.push({
    event: 'accordion_engagement',
    event_name: 'accordion_engagement',
    event_category: analytics.category,
    event_label: '',
  })
  if (question.label || key === 'quote_product_selection') {
    const label = key === 'quote_product_selection' ? 'Term life insurance' : question.label
    window.dataLayer.push({
      event: 'accordion_engagement',
      event_name: 'accordion_engagement',
      event_category: analytics.category,
      event_label: label
    })
  }
  if (!store.quoteFields) {
    store.quoteFields = {}
  }

  if (key === 'dob' && answer) {
    store.form.calculated_age = calculateAge(answer)
  }

  if (key === 'coverage_amount_from_quote' && answer) {
    if (store.form.coverage_amount_from_quote < 1000000) {
      store.form.health_match = null
      store.quoteFields.health_match = null
    }
  }

  const riderKeys = ['tdw', 'adb', 'gio', 'ctb']
  if (riderKeys.includes(key)) {
    if (answer === 'false') {
      store.form[`${key}_cost`] = 0
      store.form[`${key}_amount`] = 0
      store.quoteFields[`${key}_amount`] = 0
    } else {
      if (key !== 'tdw' && store.form[`${key}_amount`] === 0) {
        const optionWithSlider = question.option_list.filter(
            option => option.show_coverage_slider
        )
        if (optionWithSlider[0]) {
          store.form[`${key}_amount`] = Number(optionWithSlider[0].coverage_slider.value_slider.default)
          store.quoteFields[`${key}_amount`] = Number(optionWithSlider[0].coverage_slider.value_slider.default)
        }
      }
    }
  }

  store.form[key] = answer
  store.quoteFields[key] = answer

  if (errors.value[key]) {
    delete errors.value[key]
  }

  submit()
}

// DATE OF BIRTH SPECIFICS
const today = new Date()

const maxDate = computed(() => today.toISOString().split('T')[0])

const minDate = computed(() => {
  const pastDate = new Date()
  pastDate.setFullYear(today.getFullYear() - 80)
  return pastDate.toISOString().split('T')[0]
})

const thirtyYearsAgo = new Date(today.getFullYear() - 30, today.getMonth(), today.getDate())

const defaultDate = computed(() => {
  return store.form['dob'] ?? thirtyYearsAgo.toISOString().split('T')[0]
})

const dateFormat = (date) => {
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
}

// RIDER SPECIFICS
const allRiderOptions = ref(['adb', 'ctb', 'gio', 'tdw'])
const riderOptions = ref([])

function setRiderOptions() {
  const termAgesAndRiders = {
    'tdw': [18, 55],
    'adb': [18, 60],
    'gio': [18, 40],
    'ctb': [18, 55]
  }

  const term100AgesAndRiders = {
    'tdw': [30, 55],
    'adb': [30, 60],
    'gio': [30, 40],
    'ctb': [30, 55]
  }

  const age = store.form.calculated_age

  if (!age || !store.form.product_name) {
    riderOptions.value = []
    return
  }

  const riderMap = {
    'Term 10': termAgesAndRiders,
    'Term 20': termAgesAndRiders,
    'Term 30': termAgesAndRiders,
    'Term to 100': term100AgesAndRiders,
    'Whole Life': [],
    '20-Pay Whole Life': []
  }

  const selectedRiders = riderMap[store.form.product_name]

  if (selectedRiders) {
    // Filter riders based on age range
    riderOptions.value = Object.entries(selectedRiders)
        .filter(([_, [minAge, maxAge]]) => age >= minAge && age <= maxAge)
        .map(([key]) => key)
  } else {
    riderOptions.value = []
  }
}

function resetAgeConditionalFields(oldAge, newAge) {
  const ageRanges = {
    'Term 10': [18, 75],
    'Term 20': [18, 65],
    'Term 30': [18, 55],
    'Whole Life': [0, 80],
    '20-Pay Whole Life': [0, 80],
    'Term to 100': [30, 80]
  }
  let shouldReset = true
  const selectedProduct = store.form.product_name

  // get currently selected product if there is one
  // check if their age is in the range of the product
  // if it is, don't reset it
  // if it isn't, reset it
  if (selectedProduct && ageRanges[selectedProduct]) {
    const [minAge, maxAge] = ageRanges[selectedProduct]
    if (newAge >= minAge && newAge <= maxAge) {
      shouldReset = false
    }
  }

  if (shouldReset) {
    store.form.product_name = null
    store.form.quote_product_selection = null
    store.quoteFields.quote_product_selection = null
  }

  // store.form.health_match = null
  // store.quoteFields.health_match = null

  // Reset smoking if under 18
  if (newAge <= 17) {
    store.form.smoking_class = null
    store.quoteFields.smoking_class = null
  }

  // reset any riders that are not in the new set of riders
  const missingRiders = allRiderOptions.value.filter(
      rider => !riderOptions.value.includes(rider)
  )

  missingRiders.forEach((riderKey) => {
    store.form[`${riderKey}`] = null
    store.quoteFields[`${riderKey}`] = 0
    store.form[`${riderKey}_amount`] = 0
    store.quoteFields[`${riderKey}_amount`] = 0
    store.form[`${riderKey}_cost`] = 0
  })

  store.quote.complete = false
}

watch([
  () => store.form.product_name,
  () => store.form.calculated_age
], ([newProduct, newAge], [oldProduct, oldAge]) => {
  setRiderOptions()
  if (oldAge !== null && newAge !== oldAge) {
    resetAgeConditionalFields(oldAge, newAge)
  }
})

const questionsAnswered = computed(() => {
  const quote = store.quoteFields

  return Object.entries(quote).every(([key, value]) => {
    // If it's a rider question, only require it if it's in riderOptions
    if (allRiderOptions.value.includes(key) && !riderOptions.value.includes(key)) {
      return true // Skip validation for non-selected riders
    }

    if (key === 'health_match') {
      const healthCheckRequiredProducts = ['Term 10', 'Term 20', 'Term 30']
      const coverageRequired = store.form.coverage_amount_from_quote
      if (healthCheckRequiredProducts.includes(store.form.product_name) && coverageRequired > 1000000) {
        return value !== null && value !== undefined && value !== ''
      }
      return true // Skip validation if product does not require health_check
    }

    if (key === 'smoking_class') {
      if (store.form.calculated_age < 19) {
        return true
      }
    }

    // If it's a base required question, make sure it has a valid answer
    return value !== null && value !== undefined && value !== ''
  })
})

const validateForm = () => {
  errors.value = {}

  Object.entries(store.quoteFields).forEach(([key, value]) => {
    // Skip validation for non-selected rider questions
    if (allRiderOptions.value.includes(key) && !riderOptions.value.includes(key)) {
      return
    }

    if (riderOptions.value.includes(key) && store.form[`${key}_cost`] === 0) {
      submit()
    }

    // Health match validation: Required for specific products and coverage
    if (key === 'health_match') {
      const healthCheckRequiredProducts = ['Term 10', 'Term 20', 'Term 30']
      const coverageRequired = store.form.coverage_amount_from_quote
      if (healthCheckRequiredProducts.includes(store.form.product_name) && coverageRequired > 1000000) {
        if (!value) {
          errors.value[key] = 'This question is required'
        }
      }
      return
    }

    // Smoking class validation: Skip for users under 19
    if (key === 'smoking_class' && store.form.calculated_age < 19) {
      return
    }

    // Generic required field check
    if (value === null || value === undefined || value === '') {
      errors.value[key] = 'This question is required'
    }
  })

  if (Object.keys(errors.value).length > 0) {
    nextTick(() => {
      const firstErrorKey = Object.keys(errors.value)[0]
      const firstErrorElement = document.getElementById(firstErrorKey)
      if (firstErrorElement) {
        firstErrorElement.scrollIntoView({behavior: 'smooth', block: 'center'})
      }
    })
  }

  // Don't progress if they have an error in the procloud response
  if (error.value) return

  if (Object.keys(errors.value).length === 0 && questionsAnswered.value) {
    store.quote.complete = true
    emit('complete')
    return
  }

  return Object.keys(errors.value).length === 0
}

function switchToWholeLife() {
  store.quote.complete = true
  emit('complete')
}

watch(questionsAnswered, (newQuestionsAnswered) => {
  if (newQuestionsAnswered) {
    store.quote.complete = true
  } else {
    store.quote.complete = false
  }
})

onMounted(() => {
  window.dataLayer.push({
    event: 'accordion_opened',
    event_name: 'accordion_opened',
    event_category: analytics.category,
    event_label: '',
  })
  update({question_key: 'dob'}, defaultDate.value)
  setRiderOptions()
})
</script>

<template>
  <div>
    <div class="quote-questions">
      <!--      <div :key="quoteKey" v-if="quoteKey > 0">-->
      <!--      <h3>Quote Response</h3>-->
      <!--            <pre>{{ quote }}</pre>-->
      <!--      <div>{{ store.quoteFields }}</div>-->
      <!--        <div>{{ store.form }}</div>-->
      <!--            <ul>-->
      <!--              <li>{{store.form.adb_cost}} | {{store.form.adb_amount}}</li>-->
      <!--              <li>{{store.form.gio_cost}} | {{store.form.gio_amount}}</li>-->
      <!--              <li>{{store.form.ctb_cost}} | {{store.form.ctb_amount}}</li>-->
      <!--              <li>{{store.form.tdw_cost}}</li>-->
      <!--            </ul>-->
      <!--      </div>-->
      <p v-if="content.things_to_note" v-html="content.things_to_note"></p>
      <template v-for="(question, index) in data">
        <template
            v-if="!allRiderOptions.includes(question.question_key) || riderOptions.includes(question.question_key)">
          <div v-if="riderOptions.includes(question.question_key) && riderOptions.indexOf(question.question_key) === 0">
            <h3>Are you interested in adding any optional benefits to your plan?</h3>
            <p>The optional Benefits are:</p>
          </div>
          <template v-if="question.question_type === 'option_list'">
            <Question
                :id="question.question_key"
                :question="question"
                :riders="riderOptions"
                layout="row"
                v-model:option-model="store.form[question.question_key]"
                v-model:pending="pending"
                @update-answer="update(question, $event)"
                @update-slider="update($event.question, $event.answer)"
                :error="errors[question.question_key]"
            />
          </template>
          <template v-if="question.question_type === 'date'">
            <div :id="question.question_key" class="question">
              <h3>{{ question.label }}</h3>
              <div class="question-content">
                <div v-if="question.description" v-html="question.description" class="question-description"></div>
                <div class="date-question">
                  <VueDatePicker
                      v-model="store.form[question.question_key]"
                      :min-date="minDate"
                      :max-date="maxDate"
                      :start-date="defaultDate"
                      :enable-time-picker="false"
                      :clearable="false"
                      :format="dateFormat"
                      :auto-apply="true"
                      position="left"
                      @update:model-value="update(question, $event)"
                      required
                  ></VueDatePicker>
                </div>
                <span class="text-red" v-if="errors[question.question_key]">{{ errors[question.question_key] }}</span>
              </div>
            </div>
          </template>
          <template v-if="question.question_type === 'product_select'">
            <div>
              <h3>Which insurance product are you interested in?</h3>
            </div>
            <QuoteProductQuestion
                :id="question.question_key"
                :question="question"
                layout="column"
                v-model="store.form[question.question_key]"
                @update-answer="update(question, $event)"
                @complete="switchToWholeLife()"
                :error="errors[question.question_key]"
            />
          </template>
          <template v-if="question.question_type === 'value_slider'">
            <QuestionSlider
                :id="question.question_key"
                :question="question"
                v-model="store.form[question.question_key]"
                @update-answer="update(question, $event)"
            />
            <span class="text-red" v-if="errors[question.question_key]">{{ errors[question.question_key] }}</span>
          </template>
        </template>
      </template>
    </div>
    <span class="error-banner text-red" v-if="errorMessage">{{ errorMessage }}</span>
    <Disclaimers section="quote"/>
    <slot name="navigation" :validate="validateForm" :pending="pending">
    </slot>
  </div>

</template>


<style lang="scss">
.quote-questions {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.date-question {
  display: flex;
  justify-content: center;

  @media (min-width: 768px) {
    display: block;
  }
}

.dp__main {
  width: initial;
}

.dp__input_icon,
.dp--clear-btn {
  color: #414042;
}

.dp__input,
input[type="date"] {
  border: 1px solid rgba(30, 41, 59, 0.10);
  padding: 1rem;
  margin: 0.5rem;
  width: 15rem;
  text-align: center;
  color: var(--blue);
  border-radius: 0.5rem;
  text-transform: uppercase;
  transition: all .4s ease;
  cursor: pointer;

  &:hover,
  &:focus {
    border: 1px solid transparent;
    border-color: transparent !important;
    outline-offset: 2px;
    outline: var(--blue) solid 2px;
  }
}

.text-red {
  color: #c87872;
}

.error-banner {
  padding: 1rem;
  border-radius: 0.75rem;
  border: 1px solid #c87872;
  color: #c87872;
  margin: 1rem 0;
  display: inline-block;
}
</style>
