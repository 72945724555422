<script setup>
import {useEntryDataStore} from './stores/entryData'
import {computed, inject, nextTick, onMounted, ref} from 'vue'
import QuoteEmailForm from './QuoteEmailForm.vue'
import {useMoneyFormatter} from './composables/useMoneyFormatter'
import {useAnalyticsCategory} from "./composables/useAnalyticsCategory";
import Disclaimers from "./Disclaimers.vue";

const {formatMoney} = useMoneyFormatter()

const data = QUOTE.summary;
const content = QUOTE.content;
const store = useEntryDataStore()

const analytics = {
  category: 'webtool_quotesummary'
}

const emailButtonInteraction = ref(false)
const paymentIntervalTextSummary = ref('')
const paymentIntervalText = ref('')
const paymentIntervalCost = ref('')

const changePath = inject('changePath')

const quoteViewed = ref(false)


const emits = defineEmits(['complete'])

const selectedProductInfo = computed(() => {
  if (!store.form.quote_product_selection) {
    return {name: null, duration: null}
  }
  if (store.form.quote_product_selection.includes('Term100')) {
    return {name: 'Term to 100', duration: 100}
  }
  if (store.form.quote_product_selection.includes('Term10')) {
    return {name: 'Term 10', duration: 10}
  }
  if (store.form.quote_product_selection.includes('Term20')) {
    return {name: 'Term 20', duration: 20}
  }
  if (store.form.quote_product_selection.includes('Term30')) {
    return {name: 'Term 30', duration: 30}
  }
  return {name: null, duration: null}
})

function setPaymentMeta() {
  const intervals = {
    '01': {label: 'monthly', cost: store.form.monthly_premium_cost, summaryLabel: 'Monthly Premium'},
    '03': {label: 'quarterly', cost: store.form.quarterly_premium_cost, summaryLabel: 'Quarterly Premium'},
    '012': {label: 'yearly', cost: store.form.annual_premium_cost_quote, summaryLabel: null}
  }

  const mode = store.form.payment_mode
  if (mode && intervals[mode]) {
    paymentIntervalText.value = intervals[mode].label
    paymentIntervalCost.value = intervals[mode].cost
    paymentIntervalTextSummary.value = intervals[mode].summaryLabel
  }
}

const toggleQuoteViewed = () => {
  quoteViewed.value = !quoteViewed.value

  if (!emailButtonInteraction.value) {
    window.dataLayer.push({
      event: 'webtool_email_button_click',
      event_name: 'webtool_email_button_click',
      event_category: analytics.category,
      event_label: 'webtool_summary_email_button'
    })
    emailButtonInteraction.value = true
  }

  nextTick(() => {
    const formElement = document.getElementById('quote-email-form')
    if (formElement) {
      formElement.scrollIntoView({behavior: 'smooth', block: 'start'})
    }
  })
}

function switchToAdvisor() {
  window.dataLayer.push({
    event: 'accordion_completed',
    event_name: 'accordion_completed',
    event_category: 'webtool_quote',
    event_label: '',
  })
  window.dataLayer.push({
    event: 'click',
    event_name: 'click',
    event_category: 'webtool_advisorcontact',
    event_label: 'webtool_advisor_button'
  })
  changePath('advisor')
}

onMounted(() => {
  window.dataLayer.push({
    event: 'accordion_completed',
    event_name: 'accordion_completed',
    event_category: 'webtool_quote',
    event_label: '',
  })

  window.dataLayer.push({
    event: 'accordion_opened',
    event_name: 'accordion_opened',
    event_category: 'webtool_quotesummary',
    event_label: '',
  })
  setPaymentMeta()
})
</script>
<template>
  <div>
    <div v-if="!quoteViewed">
      <h3>We have your quote!</h3>
      <div class="premium-wrapper">
        <div class="premium">
          <span class="amount">{{ formatMoney(paymentIntervalCost) }}</span>
          <span>{{ paymentIntervalText }}</span>
        </div>
        <div class="ask-advisor">
          <p>Still have questions?</p>
          <button class="s-button" @click="switchToAdvisor()" aria-label="Ask an advisor for more details">
            Ask an advisor
          </button>
        </div>
      </div>
      <table class="quote-meta" aria-label="Your Details">
        <tbody>
        <tr>
          <th scope="col">Details</th>
          <td><span>Age</span><span>{{ store.form.age }}</span></td>
          <td><span>Sex</span><span>{{ store.form.sex === 'M' ? 'Male' : 'Female' }}</span></td>
          <td><span>Smoker</span><span>{{ store.form.smoking_class === 'N' ? 'No' : 'Yes' }}</span></td>
        </tr>
        <tr>
          <th scope="col">Your Insurance Needs</th>
          <td><span>Coverage</span><span>{{ formatMoney(store.form.coverage_amount_from_quote) }}</span></td>
          <td><span>Term</span><span>{{ selectedProductInfo.duration }} Years</span></td>
          <td v-if="selectedProductInfo.name"><span>Product</span><span>{{ selectedProductInfo.name }}</span></td>
        </tr>
        </tbody>
      </table>
      <div class="quote-summary">
        <h3>Summary</h3>
        <table>
          <caption class="sr-only">Summary of your insurance plan</caption>
          <thead class="sr-only">
          <tr>
            <th scope="col">Plan Item</th>
            <th scope="col">Cost</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th scope="row">Basic Plan</th>
            <td>{{ formatMoney(store.form.basic_plan_cost_quote) }}</td>
          </tr>
          <tr>
            <th scope="row" colspan="2" class="indented member-benefits"><p v-html="content.member_benefits_text"></p>
            </th>
          </tr>
          <tr>
            <th scope="row" colspan="2" class="double-indented member-benefits"><p
                v-html="content.member_benefits_extra"></p></th>
          </tr>
          <tr v-if="store.form.tdw === 'true' && store.form.tdw_cost">
            <th scope="row" class="indented">Total Disability Waiver</th>
            <td class="indented">{{ formatMoney(store.form.tdw_cost) }}</td>
          </tr>
          <tr v-if="store.form.adb === 'true' && store.form.adb_cost">
            <th scope="row" class="indented">Accidental Death Benefit</th>
            <td class="indented">{{ formatMoney(store.form.adb_cost) }}</td>
          </tr>
          <tr v-if="store.form.ctb === 'true' && store.form.ctb_cost">
            <th scope="row" class="indented">Child Term Benefit</th>
            <td class="indented">{{ formatMoney(store.form.ctb_cost) }}</td>
          </tr>
          <tr v-if="store.form.gio === 'true' && store.form.gio_cost">
            <th scope="row" class="indented">Guaranteed Insurability Option</th>
            <td class="indented">{{ formatMoney(store.form.gio_cost) }}</td>
          </tr>
          <template v-if="paymentIntervalTextSummary">
            <tr>
              <th scope="row">{{ paymentIntervalTextSummary }}</th>
              <td>{{ formatMoney(paymentIntervalCost) }}</td>
            </tr>
            <tr>
              <th scope="row">Annual Premium (if paid once a year)</th>
              <td>{{ formatMoney(store.form.annual_premium_cost_quote) }}</td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <th scope="row">Annual Premium</th>
              <td>{{ formatMoney(store.form.annual_premium_cost_quote) }}</td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
      <Disclaimers section="quote_summary"/>
    </div>
    <QuoteEmailForm v-else id="quote-email-form" :data="data.term" :submit-label="`Send me my full quote`"/>
    <slot name="navigation" :validate="toggleQuoteViewed" :quoteViewed="quoteViewed"/>
  </div>
</template>
<style scoped lang="scss">
.premium-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;

  @media(min-width: 480px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .premium {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .amount {
      font-size: 1.5rem;
      color: var(--blue);
    }
  }
}

.quote-meta {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
  border: none;
  border-top: 1px solid #D3D5D6;
  border-bottom: 1px solid #D3D5D6;
  display: block;
}

.quote-meta th,
.quote-meta td {
  text-align: left;
}

.quote-meta thead {
  display: none;
}

.quote-meta td span:first-child,
.quote-meta td:first-child {
  font-weight: bold;
}

.quote-meta tbody {
  display: block;
}

.quote-meta tr {
  display: block;
  margin-bottom: 1rem;
  padding: 1rem 0;
}

.quote-meta td {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  border: none;

  span {
    display: block;
  }
}

/* Switch to table layout on wider screens */
@media (min-width: 768px) {
  .quote-meta {
    display: table;
  }

  .quote-meta tbody {
    display: table-row-group;
  }

  .quote-meta th {
    padding: 0.75rem;
  }

  .quote-meta tr {
    display: table-row;
    margin-bottom: 0;
    padding: 0;
  }

  .quote-meta td {
    display: table-cell;
    padding: 0.75rem;
  }
}


//.quote-meta {
//  display: grid;
//  grid-template-columns: 1fr; // Default: Single column for headings
//  gap: 1rem;
//  border-top: 1px solid;
//  border-bottom: 1px solid;
//  padding: 1rem 0;
//  margin: 1rem 0;
//
//  .quote-meta-item {
//    display: flex;
//    flex-direction: column;
//    align-items: center;
//    gap: 0.5rem;
//  }
//
//  // Headings take full width on mobile
//  > div:first-of-type,
//  > div:nth-of-type(5) {
//    font-weight: bold;
//    text-align: center;
//  }
//
//  @media (min-width: 360px) { // Mobile: 3-column grid for items
//    grid-template-columns: repeat(3, 1fr);
//
//    // Keep headings full width
//    > div:first-of-type,
//    > div:nth-of-type(5) {
//      grid-column: span 3;
//    }
//  }
//
//  @media (min-width: 768px) { // Desktop: Single-row layout
//    grid-template-columns: repeat(4, 1fr);
//    align-items: center;
//
//    // Remove full-row behavior for headings on desktop
//    > div:first-of-type,
//    > div:nth-of-type(5) {
//      grid-column: auto;
//    }
//  }
//}


.quote-summary {
  margin: 0 auto;

  @media (min-width: 768px) {
    max-width: 50vw;
  }

  .quote-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    font-weight: bold;

    &.indented {
      padding-left: 1rem;
      font-weight: normal;
    }
  }

  table {
    width: 100%;

    tbody {
      tr {
        th {
          font-weight: bold;
          text-align: left;

          &.indented {
            padding-left: 1rem;
            font-weight: normal;
          }

          &.double-indented {
            padding-left: 2rem;
            font-weight: normal;
          }
        }

        td {
          font-weight: bold;
          text-align: right;

          &.indented {
            font-weight: normal;
          }
        }
      }
    }
  }

  .member-benefits:deep(p) {
    font-size: 1rem;
    margin: 0;
  }
}

.summary-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 4rem;

  .question {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }
}

.form-group {
  display: flex;
  gap: 1rem;
  padding: 0.5rem 0;
}

.form-input {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

input, select {
  min-height: 1.75rem;
  padding: 0 1rem;
  height: 2.5rem;
  border-radius: 0.3rem;
  border: 1px solid #cbd6e2;
}

label {
  &.required:after {
    content: '*';
    color: red;
  }
}

.ask-advisor {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  p {
    margin: 0;
  }

  @media (min-width: 768px) {
    gap: 1rem;
    flex-direction: row;
    justify-content: space-between;
  }
}

.email-button-wrapper {
  margin-top: 2rem;
  text-align: right;
}
</style>