<script setup>
import { ref } from 'vue'

import time from '../../../images/time.png'

const isHovered = ref(false)
</script>

<template>
  <img
      :src="time"
      @mouseover="isHovered = true"
      @mouseleave="isHovered = false"
      alt="Time Icon"
      class="h-icon"
  />
</template>

<style scoped lang="scss">

</style>
