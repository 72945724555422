import {createApp} from 'vue'
import {createPinia} from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import VueTippy from 'vue-tippy'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light-border.css'
import App from '../../vue/Tool.vue'


/**
 * Setup and append the Product Picker Vue Template
 */
export function dtcToolSetup() {
    const pinia = createPinia()
    pinia.use(piniaPluginPersistedstate)
    const app = createApp(App);
    app.use(pinia)
    app.use(VueTippy)
    app.mount('#v-dtc-tool');
}

jQuery('[data-get-started]').on('click', function (e) {
    e.preventDefault();
    let toggle = jQuery('.j-get-started__toggle');
    if (!toggle.get(0).checked) {
        toggle.trigger('click');
    }
});